<template>
    <div>
        <q-table :data="filterInvoices" :columns="columns" row-key="No" style="border-radius: 0">
            <template v-slot:top-right>
                <q-input
                    dense
                    debounce="300"
                    v-model="searchTracking"
                    placeholder="Buscar tracking"
                >
                    <q-icon slot="append" name="fas fa-search" />
                </q-input>
                <!-- <q-btn
                    label="Pagar"
                    color="accent"
                    push
                    class="text-bold q-ml-lg"
                    to="/checkout"
                    :disable="isAnInvoiceUnpaid()"
                /> -->
                <q-btn-dropdown
                    color="accent"
                    push
                    class="text-bold q-ml-md"
                    label="Descargar facturas"
                >
                    <q-list>
                        <q-item
                            clickable
                            v-close-popup
                            :disable="!selectedInvoicesToPrint.length"
                            @click="rucDataDialog = true"
                        >
                            <q-item-section>
                                <q-item-label>Descargar seleccionadas</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup @click="dateRangeDialog = true">
                            <q-item-section>
                                <q-item-label>Seleccionar por rango de fechas</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item
                            clickable
                            v-close-popup
                            @click="selectedInvoicesToPrint = []"
                            v-if="selectedInvoicesToPrint.length == data.length"
                        >
                            <q-item-section>
                                <q-item-label>Deseleccionar todo</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item
                            clickable
                            v-close-popup
                            @click="selectedInvoicesToPrint = data"
                            v-if="selectedInvoicesToPrint.length != data.length"
                        >
                            <q-item-section>
                                <q-item-label>Seleccionar todo</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
                <q-btn
                    label="Pagar"
                    color="accent"
                    class="text-bold q-ml-md"
                    push
                    to="/checkout"
                    icon-right="fas fa-dollar-sign"
                    :disable="isAnInvoiceUnpaid()"
                />
            </template>

            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th auto-width />
                    <q-th v-for="col in props.cols" :key="col.name" :props="props">
                        <span class="text-bold">{{ col.label }}</span>
                    </q-th>
                </q-tr>
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-btn
                            size="sm"
                            color="primary"
                            round
                            dense
                            @click="props.expand = !props.expand"
                        >
                            <i :class="props.expand ? 'fas fa-minus' : 'fas fa-plus'"></i>
                        </q-btn>
                    </q-td>
                    <q-td :key="'print'" :props="props" class="y-go-1x">
                        <q-checkbox
                            v-model="selectedInvoicesToPrint"
                            :val="props.row"
                            color="accent"
                        />
                    </q-td>
                    <q-td :key="'No'" :props="props" class="y-go-1x">
                        {{ props.row.No }}
                    </q-td>
                    <q-td key="price" :props="props" class="y-go-1x">
                        $ {{ parseFloat(props.row.price).toFixed(2) }}
                    </q-td>
                    <q-td key="status" :props="props">
                        <q-badge :color="returnStatus(props.row.status).color">
                            {{ returnStatus(props.row.status).status }}
                        </q-badge>
                    </q-td>
                </q-tr>
                <q-tr v-show="props.expand" :props="props">
                    <q-td colspan="100%" class="y-go-1x">
                        <div>
                            Fecha:
                            <span class="text-bold text-accent">{{
                                returnFormattedDate(props.row.creationTime)
                            }}</span>
                        </div>
                        <div>
                            Paquetes:
                            <span class="text-bold text-accent">{{
                                props.row.packages.length
                            }}</span>
                        </div>
                        <br />
                        <div v-for="(pckg, i) in props.row.packages" :key="i">
                            <div class="row text-left">
                                <div class="col-lg-8 col-xs-12">{{ pckg.tracking }}</div>
                                <div class="col-lg-2 col-xs-6">
                                    {{ pckg.calculatedWeight }}
                                    {{ pckg.type == 'air' ? 'lb' : 'pie³' }}
                                </div>
                                <div class="col-lg-2 col-xs-6">$ {{ pckg.price }}</div>
                            </div>
                            <div class="q-mt-md" v-if="pckg.aditionalCharges">
                                <div class="text-subtitle2 text-bold">Cargos adicionales</div>
                                <div class="row" v-for="(aC, i) in pckg.aditionalCharges" :key="i">
                                    <div class="col-10">
                                        <div class="text-body">{{ aC.chargeName }}</div>
                                    </div>
                                    <div class="col-2">
                                        <div class="text-body">
                                            $ {{ aC.chargeAmount.toFixed(2) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <q-separator class="q-my-md" v-if="i < props.row.packages.length - 1" />
                        </div>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
        <vue-html2pdf
            :enable-download="true"
            :paginate-elements-by-height="1400"
            filename="YGO_EXPORT_INVOICES"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="letter"
            pdf-orientation="portrait"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <div v-for="(invoice, i) in selectedInvoicesToPrint" :key="invoice.No">
                    <InvoiceTemplate
                        :ruc="rucNo"
                        :dv="dvNo"
                        :business="businessName"
                        :invoiceData="invoice"
                        :user="userData"
                    />
                    <div class="html2pdf__page-break" v-if="i < selectedInvoicesToPrint.length" />
                </div>
            </section>
        </vue-html2pdf>
        <q-dialog v-model="rucDataDialog">
            <q-card>
                <q-card-section class="bg-accent text-white">
                    <div class="text-h6 text-bold">Datos de factura</div>
                </q-card-section>
                <q-card-section>
                    <div class="text-body2 q-mb-md">
                        Llena los espacios si deseas generar tus facturas con tu RUC y DV.
                    </div>
                    <div class="row q-mb-md">
                        <div class="col-8 q-pr-sm">
                            <q-input label="RUC (opcional)" filled color="accent" v-model="rucNo" />
                        </div>
                        <div class="col-4 q-pl-sm">
                            <q-input
                                label="DV (opcional)"
                                filled
                                color="accent"
                                v-model="dvNo"
                                type="number"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <q-input
                            label="Compañia"
                            filled
                            color="accent"
                            class="full-width"
                            v-model="businessName"
                        />
                    </div>
                </q-card-section>
                <q-card-section>
                    <q-checkbox
                        label="Guardar para futuras facturas"
                        v-model="saveRucData"
                        :disable="(!rucNo || !dvNo || !businessName) && !saveRucData"
                    />
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn label="Cancelar" flat color="grey-8" v-close-popup />
                    <q-btn label="Descargar" push color="primary" @click="exportPdf()" />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <q-dialog v-model="dateRangeDialog">
            <q-card>
                <q-form @submit="selectByDateRange()">
                    <q-card-section class="bg-accent text-white">
                        <div class="text-h6 text-bold">Seleccionar por rango de fechas</div>
                    </q-card-section>
                    <q-card-section>
                        <q-input
                            filled
                            label="Fecha inicial"
                            v-model="initialDate"
                            mask="date"
                            :rules="['date']"
                            placeholder="YYYY/MM/DD"
                        >
                            <template v-slot:append>
                                <q-icon name="fas fa-calendar" class="cursor-pointer">
                                    <q-popup-proxy
                                        ref="qDateProxy"
                                        transition-show="scale"
                                        transition-hide="scale"
                                    >
                                        <q-date v-model="initialDate">
                                            <div class="row items-center justify-end">
                                                <q-btn
                                                    v-close-popup
                                                    label="Cerrar"
                                                    color="primary"
                                                    flat
                                                />
                                            </div>
                                        </q-date>
                                    </q-popup-proxy>
                                </q-icon>
                            </template>
                        </q-input>
                        <q-input
                            filled
                            label="Fecha final"
                            v-model="endDate"
                            mask="date"
                            :rules="['date']"
                            placeholder="YYYY/MM/DD"
                        >
                            <template v-slot:append>
                                <q-icon name="fas fa-calendar" class="cursor-pointer">
                                    <q-popup-proxy
                                        ref="qDateProxy"
                                        transition-show="scale"
                                        transition-hide="scale"
                                    >
                                        <q-date v-model="endDate">
                                            <div class="row items-center justify-end">
                                                <q-btn
                                                    v-close-popup
                                                    label="Cerrar"
                                                    color="primary"
                                                    flat
                                                />
                                            </div>
                                        </q-date>
                                    </q-popup-proxy>
                                </q-icon>
                            </template>
                        </q-input>
                    </q-card-section>
                    <q-card-actions align="right">
                        <q-btn label="Cancelar" flat color="grey-8" v-close-popup />
                        <q-btn label="Seleccionar" push color="primary" type="submit" />
                    </q-card-actions>
                </q-form>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
import dates from '@/mixins/dates'
import VueHtml2pdf from 'vue-html2pdf'
import InvoiceTemplate from '@/components/UsersDashboard/InvoiceTemplate'

export default {
    mixins: [dates],
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        userData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            rucDataDialog: false,
            saveRucData: false,
            dateRangeDialog: false,
            rucNo: '',
            dvNo: '',
            businessName: '',
            initialDate: '',
            endDate: '',
            searchTracking: '',
            columns: [
                {name: 'print', label: 'Descargar'},
                {name: 'No', label: 'No. Factura', field: 'No', sortable: true},
                {name: 'price', label: 'Monto ($)', field: 'price', sortable: true},
                // {name: 'packages', label: 'Paquetes', field: 'packages', sortable: true},
                // {name: 'creationTime', label: 'Fecha', field: 'creationTime', sortable: true},
                {name: 'status', label: 'Estatus', field: 'status', sortable: true},
            ],
            selectedInvoicesToPrint: [],
        }
    },
    methods: {
        returnStatus(status) {
            if (status == 'unpaid') return {status: 'Pendiente', color: 'red', icon: 'dollar-sign'}
            if (status == 'paid') return {status: 'Pagado', color: 'green', icon: 'dollar-sign'}
            if (status == 'review')
                return {status: 'En Proceso', color: 'amber', icon: 'dollar-sign'}
            if (status == 'delivered') return {status: 'Entregado', color: 'primary', icon: 'box'}
        },
        isAnInvoiceUnpaid() {
            let data = this.data.filter(inv => inv.status === 'unpaid')
            return !(data.length > 0)
        },
        exportPdf() {
            if (this.rucNo && this.dvNo && this.businessName && this.saveRucData)
                localStorage.setItem(
                    'ygoRucData',
                    JSON.stringify({ruc: this.rucNo, dv: this.dvNo, business: this.businessName})
                )
            else localStorage.removeItem('ygoRucData')
            this.$refs.html2Pdf.generatePdf()
            this.rucDataDialog = false
        },
        selectByDateRange() {
            this.selectedInvoicesToPrint = []
            let sd = this.initialDate.split('/')
            let ed = this.endDate.split('/')
            sd = new Date(sd[0], sd[1] - 1, sd[2])
            ed = new Date(ed[0], ed[1] - 1, ed[2])
            this.data.forEach(inv => {
                if (inv.creationTime >= sd && inv.creationTime <= ed)
                    this.selectedInvoicesToPrint.push(inv)
            })
            if (this.dateRangeDialog.length <= 0) {
                alert('No se encontraron facturas en ese rango de fechas.')
                return
            }
            this.dateRangeDialog = false
        },
    },
    computed: {
        filterInvoices() {
            let filteredInvoices = []
            this.data.forEach(inv => {
                inv.packages.forEach(pckg => {
                    if (pckg.tracking.toLowerCase().includes(this.searchTracking.toLowerCase())) {
                        if (!filteredInvoices.includes(inv)) {
                            filteredInvoices.push(inv)
                        }
                    }
                })
            })
            return filteredInvoices
        },
    },
    mounted() {
        let rucData = localStorage.getItem('ygoRucData')
        if (rucData) {
            rucData = JSON.parse(rucData)
            this.saveRucData = true
            this.rucNo = rucData.ruc
            this.dvNo = rucData.dv
            this.businessName = rucData.business
        }
    },
    // watch: {
    //     saveRucData: function() {
    //         if (!this.saveRucData) {
    //             this.rucNo = ''
    //             this.dvNo = ''
    //         }
    //     },
    // },
    components: {
        VueHtml2pdf,
        InvoiceTemplate,
    },
}
</script>