import moment from 'moment'
export default {
    methods: {
        returnFormattedDate(date, format = 'DD/MM/YYYY') {
            return moment(date).format(format)
        },
        sortDate(dateA, dateB, direction = 'asc') {
            return (
                (moment(dateA).isBefore(moment(dateB))
                    ? -1
                    : moment(dateA).isAfter(moment(dateB))
                    ? 1
                    : 0) * (direction === 'asc' ? 1 : -1)
            )
        },
    },
}
