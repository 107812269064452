var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation-bar bg-white text-white q-py-sm"},[_c('div',{staticClass:"row"},[_c('q-space'),_c('div',{staticClass:"col-lg-9 col-md-12 col-sm-12 col-xs-12 q-px-md"},[(_vm.pagina_origen != 'life')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-1 col-md-1 col-sm-12 col-xs-3 column flex-center"},[(_vm.pagina_origen != 'life')?_c('router-link',{attrs:{"to":"/"}},[_c('q-img',{staticStyle:{"width":"72px","cursor":"pointer"},attrs:{"src":require('@/assets/logo_ygo.png')}})],1):_vm._e()],1),_c('div',{staticClass:"col-lg-9 col-md-9 col-sm-12 col-xs-12 column flex-center"},[(_vm.pagina_origen != 'life')?_c('div',{staticClass:"row"},_vm._l((_vm.navLinks),function(link){return _c('router-link',{key:link.route,staticClass:"q-mx-sm",attrs:{"to":link.route}},[_c('div',{staticClass:"text-caption text-bold",style:(_vm.$route.fullPath == link.route
                                        ? 'border-bottom: solid 3px #01bcd4'
                                        : 'border-bottom: solid 3px #fff')},[_vm._v(" "+_vm._s(link.label)+" ")])])}),1):_vm._e()]),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-12 col-xs-12 column flex-center"},[(
                            _vm.$router.currentRoute.path != '/user' &&
                                _vm.$router.currentRoute.path != '/checkout'
                        )?_c('q-btn',{staticClass:"text-bold",attrs:{"push":"","label":"Iniciar sesión","color":"accent","to":"/user"}}):_vm._e()],1)]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-1 col-md-1 col-sm-12 col-xs-3 column flex-center"},[_c('router-link',{attrs:{"to":"/?page=life"}},[_c('q-img',{staticStyle:{"width":"200px","cursor":"pointer"},attrs:{"src":"https://tamitut.com/PAYA/images/lifelinewhite.png"}})],1)],1),_c('div',{staticClass:"col-lg-9 col-md-9 col-sm-12 col-xs-12 column flex-center"},[(_vm.pagina_origen != 'life')?_c('div',{staticClass:"row"},_vm._l((_vm.navLinks),function(link){return _c('router-link',{key:link.route,staticClass:"q-mx-sm",attrs:{"to":link.route}},[_c('div',{staticClass:"text-caption text-bold",style:(_vm.$route.fullPath == link.route
                                        ? 'border-bottom: solid 3px #01bcd4'
                                        : 'border-bottom: solid 3px #fff')},[_vm._v(" "+_vm._s(link.label)+" ")])])}),1):_vm._e()]),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-12 col-xs-12 column flex-center"},[(
                            _vm.$router.currentRoute.path != '/user' &&
                                _vm.$router.currentRoute.path != '/checkout'
                        )?_c('q-btn',{staticClass:"text-bold",attrs:{"push":"","label":"Iniciar sesión","color":"accent","to":"/user"}}):_vm._e()],1)])]),_c('q-space')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }