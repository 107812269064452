<template>
    <div v-if="!loading">
        <ygo-alert
            :display="displayAlert"
            :title="alertTitle"
            :message="alertMessage"
            :type="alertType"
            @accept="displayAlert = false"
        ></ygo-alert>
        <q-tabs
            v-model="tab"
            inline-label
            :breakpoint="0"
            align="justify"
            class="bg-white text-accent shadow-2"
        >
            <q-tab name="info" label="Información" />
            <q-tab name="plan" label="Planes" />
            <q-tab name="address" label="Direcciones" />
        </q-tabs>

        <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="info">
                <div class="row q-mb-md">
                    <div class="col q-pa-sm">
                        <q-input
                            filled
                            label="Nombre"
                            class="on-left"
                            v-model="userInformationData.user.name"
                            :disable="!editInformation"
                            ref="name"
                        />
                    </div>
                    <div class="col q-pa-sm">
                        <q-input
                            filled
                            label="Apellido"
                            class="on-right"
                            v-model="userInformationData.user.lastName"
                            :disable="!editInformation"
                        />
                    </div>
                </div>
                <div class="row q-mb-md" v-if="isBusinessAccount()">
                    <div class="col q-pa-sm">
                        <q-input
                            filled
                            label="Nombre de empresa"
                            v-model="userInformationData.user.companyName"
                            :disable="!editInformation"
                        />
                    </div>
                </div>
                <div class="row q-mb-md">
                    <div class="col q-pa-sm">
                        <q-input
                            filled
                            readonly
                            label="Correo electronico"
                            v-model="userInformationData.user.email"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-4 q-pa-sm">
                        <q-select
                            filled
                            label="Pais"
                            :options="countryCodes"
                            emit-value
                            v-model="userInformationData.user.countryCode"
                            :disable="!editInformation"
                        />
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-8 q-pa-sm">
                        <q-input
                            filled
                            label="Celular *"
                            v-model="userInformationData.user.phone"
                            :disable="!editInformation"
                            :rules="[val => val.length > 0 || 'El campo es obligatorio']"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 q-pa-sm">
                        <q-input
                            filled
                            label="Fecha de Nacimiento"
                            v-model="userInformationData.user.birthdate"
                            mask="date"
                            :rules="['date']"
                            :disable="!editInformation"
                        >
                            <template v-slot:append>
                                <q-icon name="fas fa-calendar" class="cursor-pointer">
                                    <q-popup-proxy
                                        ref="qDateProxy"
                                        transition-show="scale"
                                        transition-hide="scale"
                                    >
                                        <q-date v-model="userInformationData.user.birthdate">
                                            <div class="row items-center justify-end">
                                                <q-btn
                                                    v-close-popup
                                                    label="Close"
                                                    color="primary"
                                                    flat
                                                />
                                            </div>
                                        </q-date>
                                    </q-popup-proxy>
                                </q-icon>
                            </template>
                        </q-input>
                    </div>
                </div>
                <div class="row">
                    <q-btn
                        push
                        size="sm"
                        color="accent"
                        label="Guardar"
                        @click="$emit('saveUserProfile')"
                    />
                </div>
            </q-tab-panel>

            <q-tab-panel name="plan">
                <div class="row q-mb-md justify-center">
                    <div class="col-lg-4 col-xs-12 q-pa-sm">
                        <div class="rateTileSelected q-pa-md rounded-borders text-center">
                            <q-icon name="fas fa-plane-departure" size="2em" class="q-mb-sm" />
                            <br />
                            <div class="text-caption">Tarifa aerea</div>
                            <div class="text-h6 q-mb-sm text-bold">{{ returnRateName('air') }}</div>
                            <q-separator class="q-mb-sm" dark />
                            <div class="text-h5 text-bold q-mt-md q-mb-md" v-if="showPrice()">
                                *$
                                {{ returnRatePrice('air') }}
                            </div>
                            <!-- <div class="text-body2" v-if="returnRateName() === 'Plan Cero Volumen'">
                                * La libra a considerar es unicamente el peso real del paquete.
                                <br />
                            </div> -->
                            <!-- <div class="text-body2" v-else>
                                * La libra a considerar es la mayor entre el peso real y el peso
                                volumetrico del paquete. <br />
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-12 q-pa-sm">
                        <div class="rateTileSelected q-pa-md rounded-borders text-center">
                            <q-icon name="fas fa-ship" size="2em" class="q-mb-sm" />
                            <br />
                            <div class="text-caption">Tarifa maritima</div>
                            <div class="text-h6 q-mb-sm text-bold">{{ returnRateName('sea') }}</div>
                            <q-separator class="q-mb-sm" dark />
                            <div class="text-h5 text-bold q-mt-md q-mb-md" v-if="showPrice()">
                                *$
                                {{ returnRatePrice('sea') }}
                            </div>
                            <!-- <div class="text-body2" v-if="returnRateName() === 'Plan Cero Volumen'">
                                * La libra a considerar es unicamente el peso real del paquete.
                                <br />
                            </div> -->
                            <!-- <div class="text-body2" v-else>
                                * La libra a considerar es la mayor entre el peso real y el peso
                                volumetrico del paquete. <br />
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-12 q-pa-sm">
                        <q-input
                            readonly
                            filled
                            color="primary"
                            label="Sucursal"
                            :value="userInformationData.user.subsidiaryName"
                            class="q-mb-md"
                        />
                        <div class="text-body full-width">
                            Para solicitar cambio de tarifa o sucursal, escribenos a
                            <a href="mailto:contacto@y-go.com.pa" class="text-primary text-bold">
                                contacto@y-go.com.pa</a
                            >.
                        </div>
                        <br />
                        <div class="text-body full-width">
                            Lee mas sobre nuestras tarifas
                            <a href="#" class="text-primary text-bold">aqui</a>.
                        </div>
                    </div>
                </div>
            </q-tab-panel>

            <q-tab-panel name="address">
                <q-list bordered class="rounded-borders q-mb-md">
                    <q-expansion-item
                        expand-separator
                        label="Direccion 1"
                        class="text-bold text-white"
                        default-opened
                        group="addressGroup"
                        header-class="bg-primary text-white"
                        expand-icon-class="text-white"
                    >
                        <q-card>
                            <q-card-section>
                                <div class="row q-mb-md">
                                    <div class="col">
                                        <GoogleMaps
                                            class="q-mb-md"
                                            v-if="Object.keys(center).length > 0"
                                            @markerPosition="setMarkerPosition"
                                            @newMarkerPosition="setNewMarkerPosition"
                                            :editable="editInformation"
                                            :markers="markers"
                                            :mapCenter="center"
                                        ></GoogleMaps>
                                    </div>
                                </div>
                                <q-form @submit="sendAddressUpdate()">
                                    <div class="row q-mb-md">
                                        <div class="col">
                                            <q-select
                                                filled
                                                class="y-go-1x q-mb-md"
                                                label="Distrito"
                                                :options="locations"
                                                map-options
                                                emit-value
                                                :rules="[val => !!val || 'Campo obligatorio']"
                                                v-model="userInformationData.user.district"
                                            />
                                            <q-input
                                                filled
                                                label="Direccion de entrega"
                                                class="q-mb-md"
                                                v-model="userInformationData.user.address"
                                                :rules="[val => !!val || 'Campo obligatorio']"
                                            />
                                            <q-input
                                                filled
                                                label="Notas adicionales de direccion"
                                                v-model="userInformationData.user.addressExtra"
                                                :disable="!editInformation"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <q-btn
                                            push
                                            size="sm"
                                            color="accent"
                                            label="Guardar"
                                            type="submit"
                                        />
                                    </div>
                                </q-form>
                            </q-card-section>
                        </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                        expand-separator
                        label="Direccion 2"
                        class="text-bold"
                        group="addressGroup"
                        header-class="bg-primary text-white"
                        expand-icon-class="text-white"
                    >
                        <q-card>
                            <q-card-section>
                                <div class="row q-mb-md">
                                    <div class="col">
                                        <GoogleMaps
                                            class="q-mb-md"
                                            v-if="Object.keys(center2).length > 0"
                                            @markerPosition="setMarkerPosition2"
                                            @newMarkerPosition="setNewMarkerPosition2"
                                            :editable="editInformation"
                                            :markers="markers2"
                                            :mapCenter="center2"
                                        ></GoogleMaps>
                                    </div>
                                </div>
                                <q-form @submit="sendAddress2Update()">
                                    <div class="row q-mb-md">
                                        <div class="col">
                                            <q-select
                                                filled
                                                class="y-go-1x q-mb-md"
                                                label="Distrito"
                                                :options="locations"
                                                map-options
                                                emit-value
                                                :rules="[val => !!val || 'Campo obligatorio']"
                                                v-model="userInformationData.user.district2"
                                            />
                                            <q-input
                                                filled
                                                label="Direccion de entrega"
                                                class="q-mb-md"
                                                :rules="[val => !!val || 'Campo obligatorio']"
                                                v-model="userInformationData.user.address2"
                                            />
                                            <q-input
                                                filled
                                                label="Notas adicionales de direccion"
                                                v-model="userInformationData.user.addressExtra2"
                                                :disable="!editInformation"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <q-btn
                                            push
                                            size="sm"
                                            color="accent"
                                            label="Guardar"
                                            type="submit"
                                        />
                                    </div>
                                </q-form>
                            </q-card-section>
                        </q-card>
                    </q-expansion-item>
                </q-list>
            </q-tab-panel>
        </q-tab-panels>
    </div>
</template>
<script>
import GoogleMaps from '../../components/general/GoogleMaps'
import SubsidiariesNames from '@/enums/subsidiariesNames'
import * as api from '@/api/api'

export default {
    props: {
        userInformationData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            location: [],
            markers: [],
            center: {},
            location2: [],
            markers2: [],
            center2: {},
            editInformation: true,
            displayAlert: false,
            alertTitle: '',
            alertMessage: '',
            alertType: '',
            tab: 'info',
            countryCodes: require('@/assets/country_codes.json'),
            loading: false,
            locations: [],
            districts: [],
        }
    },
    methods: {
        showPrice() {
            if (this.isBusinessAccount() && !this.userInformationData.user.businessAproved)
                return false
            return true
        },
        isBusinessAccount() {
            let seaRate = this.userInformationData.user.seaRate
            let airRate = this.userInformationData.user.airRate
            if (!seaRate || !airRate) return false
            return seaRate.isBusiness || airRate.isBusiness
        },
        returnRateName(type) {
            return this.userInformationData.user[`${type}Rate`].name
        },
        returnRatePrice(type) {
            return this.userInformationData.user[`${type}Rate`].rate.toFixed(2)
        },
        //TODO: Revisar estas funcione por que existen 2
        async sendAddressUpdate() {
            if (Object.keys(this.location).length > 0) {
                this.userInformationData.user.coordinates = this.location
            }
            this.$emit('saveUserProfile')
        },
        async sendAddress2Update() {
            if (Object.keys(this.location2).length > 0) {
                this.userInformationData.user.coordinates2 = this.location2
            }
            this.$emit('saveUserProfile')
        },
        setNewMarkerPosition(event) {
            this.markers = [{position: event}]
            this.location = event
        },
        setMarkerPosition(event) {
            this.location = event
        },
        setNewMarkerPosition2(event) {
            this.markers2 = [{position: event}]
            this.location2 = event
        },
        setMarkerPosition2(event) {
            this.location2 = event
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.center2 = {
                        lat: parseFloat(position.coords.latitude),
                        lng: parseFloat(position.coords.longitude),
                    }
                    this.markers2.push({position: this.center2})
                },
                error => {
                    this.center2 = {
                        lat: parseFloat(9.068463),
                        lng: parseFloat(-79.452694),
                    }
                    this.markers2.push({position: this.center2})
                }
            )
        },
        setLocations(subsidiaryId){
            this.locations.length = 0;
            const districtList = this.districts.filter(district => district.province === subsidiaryId);
            districtList.forEach((district) => {
                this.locations.push({
                    label: district.name,
                    value: district.name,
                })    
            })
        },

        setSubsidiaryName(subsidiaryId){
            return `Ciudad de ${SubsidiariesNames.find((subsidiaryName) => subsidiaryName.id === subsidiaryId).name}`;
        },
    },
    components: {
        GoogleMaps,
    },
    async mounted() {
        this.loading = true
        this.center = this.userInformationData.user.coordinates
        this.markers.push({position: this.center})
        if (this.userInformationData.user.coordinates2 === undefined) {
            this.geolocate()
        } else {
            this.center2 = this.userInformationData.user.coordinates2
            this.markers2.push({position: this.center2})
        }
        this.loading = false

        await api.GetAllDistricts().then(response => {
            this.districts = response.data.data;
        });

        this.userInformationData.user.subsidiaryName = this.setSubsidiaryName(this.userInformationData.user.subsidiary);
        this.setLocations(this.userInformationData.user.subsidiary);
    },
}
</script>

<style>
.rateTile {
    background-color: #eee;
    cursor: pointer;
}

.rateTile .text-h6 {
    color: #01bcd4;
}

.rateTile .text-h5 {
    color: #ff5722;
}

.rateTile .text-subtitle2 {
    color: #000;
}

.rateTileSelected {
    background-color: #ff5722;
    color: #fff;
    cursor: pointer;
}
</style>
