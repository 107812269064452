<template>
  <q-page class="q-pa-lg">
    <loading-alert :display="displayLoading"></loading-alert>
    <ygo-alert
      :display="displayAlert"
      :title="alertTitle"
      :message="alertMessage"
      :type="alertType"
      @accept="displayAlert = false"
    ></ygo-alert>
    <div>
      <div class="row q-mb-lg">
        <div class="text-h5">
          <div class="col q-px-md">Usuarios Y-Gó</div>
        </div>
      </div>
      <div class="row q-mb-lg">
        <div class="col-lg-2 q-px-md">
          <q-input
            dense
            filled
            label="Casillero"
            v-model="searchBox"
            ref="box"
          />
        </div>
        <div class="col-lg-2 q-px-md">
          <q-input dense filled label="Correo" v-model="searchEmail" />
        </div>
        <div class="col-lg-2 q-px-md">
          <q-input dense filled label="Nombre" v-model="searchName" />
        </div>
        <div class="col-lg-2 q-px-md">
          <q-input dense filled label="Apellido" v-model="searchLastName" />
        </div>
        <div class="col-lg-2 q-px-md">
          <q-input dense filled label="Sucursal" v-model="searchSubsidiary" />
        </div>
      </div>
      <div class="row q-mb-xl">
        <div class="col q-px-md">
          <q-table
            :data="filterUserTable"
            :columns="usersColumns"
            row-key="name"
            :pagination.sync="initialPagination"
            class="full-width my-sticky-header-table"
            title="Usuarios"
          >
            <template v-slot:header="props">
              <q-tr :props="props">
                <q-th
                  v-for="col in props.cols"
                  :key="col.name"
                  :props="props"
                  >{{ col.label }}</q-th
                >
                <q-th>Editar</q-th>
              </q-tr>
            </template>

            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td key="box" :props="props">{{ props.row.box }} </q-td>
                <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                <q-td key="lastName" :props="props">{{
                  props.row.lastName
                }}</q-td>
                <q-td key="email" :props="props">{{ props.row.email }}</q-td>
                <td key="affiliateCardNo">
                  <div
                    class="text-accent text-bold"
                    v-if="props.row.affiliateCardNo"
                  >
                    AFILIADO
                  </div>
                  <div class="text-grey-7 text-bold" v-else>SIN AFILIACIÓN</div>
                </td>
                <q-td key="airrate" :props="props">
                  <span v-if="props.row.airRate">
                    {{ returnRateName(props.row.airRate) }}
                  </span>
                  <q-badge color="grey-7" v-else> SIN TARIFA ASIGNADA </q-badge>
                </q-td>
                <q-td key="searate" :props="props">
                  <span v-if="props.row.seaRate">
                    {{ returnRateName(props.row.seaRate) }}
                  </span>
                  <q-badge color="grey-7" v-else> SIN TARIFA ASIGNADA </q-badge>
                </q-td>
                <q-td key="subsidiary" :props="props">{{
                  props.row.subsidiaryName
                }}</q-td>
                <q-td auto-width>
                  <q-btn
                    size="sm"
                    color="primary"
                    round
                    dense
                    icon="fas fa-user-edit"
                    flat
                    :to="`/user-details/${props.row.id}`"
                  />
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import * as api from "@/api/api";
import Subsidiary from "@/enums/subsidiariesNames";
import dates from "@/mixins/dates";
import { collection, onSnapshot, query } from "@firebase/firestore";
import { firestore } from "../../main";
export default {
  mixins: [dates],
  data() {
    return {
      displayLoading: false,
      displayAlert: false,
      displayConfirm: false,
      ratesDialog: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "",
      searchName: "",
      searchLastName: "",
      searchEmail: "",
      searchBox: "",
      searchSubsidiary: "",
      rateName: "",
      rateRate: "",
      workingDeletedId: "",
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsPerPage: 0,
      },
      usersColumns: [
        {
          name: "box",
          align: "left",
          label: "Casillero",
          field: "box",
          sortable: true,
        },
        {
          name: "name",
          align: "left",
          label: "Nombre",
          field: "name",
          sortable: true,
        },
        {
          name: "lastName",
          align: "left",
          label: "Apellido",
          field: "lastName",
          sortable: true,
        },
        {
          name: "email",
          align: "left",
          label: "Correo",
          field: "email",
          sortable: true,
        },
        {
          name: "afiliated",
          align: "left",
          label: "Afiliacion",
          field: "affiliateCardNo",
          sortable: true,
        },
        {
          name: "airrate",
          align: "left",
          label: "Tarifa Aerea",
          field: "rate",
          sortable: true,
        },
        {
          name: "searate",
          align: "left",
          label: "Tarifa Maritima",
          field: "rate",
          sortable: true,
        },
        {
          name: "subsidiary",
          align: "left",
          label: "Sucursal",
          field: "subsidiary",
          sortable: true,
        },
      ],
      ratesData: [],
      usersData: [],
      filteredUserData: [],
    };
  },
  methods: {
    returnRateName(rateId) {
      let rate = this.ratesData.find((rate) => rate.id === rateId);
      return rate !== undefined
        ? `${rate.name} | $${parseFloat(rate.rate).toFixed(2)}`
        : "NaN";
    },
    getAllRates() {
      try {
        api.ReturnAllRates().then((response) => {
          this.ratesData = response.data.data;
          this.ratesData = this.ratesData.sort(function(x, y) {
            return x.name == "Default" ? -1 : y.name == "Default" ? 1 : 0;
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    getAllUsers() {
      try {
        api.ReturnAllUsers().then((response) => {
          this.usersData = response.data.data;
          this.usersData = this.usersData.sort((x, y) =>
            x.box > y.box ? 1 : -1
          );
        });
      } catch (error) {
        console.log(error);
      }
    },
    addToUsers(id, data) {
      data.id = id;
      data.subsidiaryName = this.getSubsidiaryName(data.subsidiary);
      this.usersData.push(data);
    },
    editInUsers(id, data) {
      let name = data.name + " " + data.lastName;
      data.name = name;
      data.id = id;
      this.usersData.forEach((user, index) => {
        if (user.id === id) {
          this.usersData.splice(index, 1, data);
        }
      });
    },
    removeInUsers(id) {
      this.usersData.forEach((user, index) => {
        if (user.id === id) {
          this.usersData.splice(index, 1);
        }
      });
    },
    verifyIncludes(str, incl) {
      if (str != null && incl != null) {
        let sanitizedStr = this.sanitizeStrings(str);
        let sanitizedIncl = this.sanitizeStrings(incl);
        return sanitizedStr.includes(sanitizedIncl);
      } else {
        return false;
      }
    },
    sanitizeStrings(str) {
      return str
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    },
    getSubsidiaryName(id) {
      const name =
        id >= 0
          ? Subsidiary.find((subsidiary) => subsidiary.id === id).name
          : "Por Definir";
      return name;
    },
  },
  computed: {
    filterUserTable() {
      let filteredData = [];
      this.usersData.forEach((user) => {
        let userSubsidiary = "";

        switch (user.subsidiary) {
          case 0:
            userSubsidiary = "Ciudad de Panama";
            break;
          case 1:
            userSubsidiary = "Ciudad de Penonome";
            break;
          case 2:
            userSubsidiary = "Ciudad de David";
            break;
          case 3:
            userSubsidiary = "Santa Elena";
            break;
        }

        if (!user.subsidiaryName) {
          user.subsidiaryName = userSubsidiary;
        }

        if (
          this.verifyIncludes(user.box, this.searchBox) &&
          this.verifyIncludes(user.email, this.searchEmail) &&
          this.verifyIncludes(user.name, this.searchName) &&
          this.verifyIncludes(user.lastName, this.searchLastName) &&
          this.verifyIncludes(user.subsidiaryName, this.searchSubsidiary)
        ) {
          filteredData.push(user);
        }
      });

      return filteredData.sort((a, b) =>
        this.sortDate(a.creationTime, b.creationTime, "desc")
      );
    },
  },
  watch: {
    usersData(newValue, oldValue) {
      this.filteredUserData = newValue;
    },
  },
  mounted() {
    this.$refs.box.focus();

    this.getAllUsers();
    this.getAllRates();

    // const q = query(collection(firestore, "invoices"));
    // const unsubscribe = onSnapshot(
    //   q,
    //   (snapshot) => {
    //     snapshot.docChanges().forEach((change) => {
    //       if (change.type === "added") {
    //         this.addToUsers(change.doc.id, change.doc.data());
    //       }
    //       if (change.type === "modified") {
    //         this.editInUsers(change.doc.id, change.doc.data());
    //       }
    //       if (change.type === "removed") {
    //         this.removeInUsers(change.doc.id);
    //       }
    //     });
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  },
};
</script>
