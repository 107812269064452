import { render, staticRenderFns } from "./CompleteRegistrationForm.vue?vue&type=template&id=07eb9fab&"
import script from "./CompleteRegistrationForm.vue?vue&type=script&lang=js&"
export * from "./CompleteRegistrationForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerPuff from 'quasar/src/components/spinner/QSpinnerPuff.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSpace from 'quasar/src/components/space/QSpace.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QImg,QForm,QStepper,QStep,QSeparator,QInput,QSelect,QIcon,QPopupProxy,QDate,QBtn,QSpinnerPuff,QLinearProgress,QStepperNavigation,QDialog,QCardSection,QCardActions,QSpace});qInstall(component, 'directives', {ClosePopup});
