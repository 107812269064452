<template>
    <q-page style="min-height: auto !important">
        <div class="row bg-primary q-mb-xl">
            <div class="col-lg-7 col-xs-12 q-pt-md q-pb-sm text-center full-width">
                <div class="text-h4 text-white text-bold">POLITICAS DE PRIVACIDAD</div>
            </div>
        </div>
        <div class="row">
            <q-space />
            <div class="col-lg-7 col-md-10 q-px-lg" style="height: 500px; overflow-y: scroll;">
                <p class="text-bold">
                    Las presentes Políticas de Privacidad regulan la recopilación, tratamiento y
                    acceso a los datos proporcionados por los Clientes en la de la página web
                    <a href="https://y-go.com.pa">https://www.y-go.com.pa</a>, de conformidad con la
                    legislación vigente.
                </p>
                <p class="text-bold">i. Política de Privacidad</p>
                <p class="text-bold q-pl-lg">
                    La política de privacidad establece los términos en que usa y protege la
                    información que es proporcionada por los Clientes al momento de utilizar la
                    Plataforma. La Empresa está comprometida con la seguridad de los datos de sus
                    Clientes. Cuando la Plataforma solicita llenar los campos de información
                    personal con la cual el Cliente pueda ser identificado, lo hace asegurando que
                    solo se empleará dicha información de acuerdo con los términos de este
                    documento. Sin embargo, esta Política de Privacidad puede cambiar con el tiempo
                    o ser actualizada por lo que el Cliente debe revisar continuamente esta página
                    para asegurarse que está de acuerdo con dichos cambios.
                </p>
                <p class="text-bold">ii. Información recopilada</p>
                <p class="text-bold q-pl-lg">
                    La Plataforma podrá recopilar información personal de sus Clientes, como, por
                    ejemplo: nombre, información de contacto, dirección de correo electrónico e
                    información demográfica. Así mismo, cuando sea necesario, podrá ser requerida
                    información específica para procesar algún pedido o realizar una entrega o
                    facturación. Estos datos serán tratados en conformidad con la Ley 81 de 26 de
                    marzo de 2019, publicada en Gaceta Oficial 28743-A de 29 de marzo de 2019, sobre
                    protección de datos personales.
                </p>
                <p class="text-bold">iii. Uso de la información recopilada</p>
                <p class="text-bold q-pl-lg">
                    La Plataforma utiliza la información recopilada con el fin de proporcionar el
                    mejor servicio posible, particularmente para mantener un registro de Clientes,
                    de pedidos, y general mejorar los servicios ofrecidos.
                </p>
                <p class="text-bold q-pl-lg">
                    Es posible que sean enviados correos electrónicos periódicamente a través de la
                    Plataforma con ofertas especiales u otra información publicitaria que
                    consideremos relevante para el Cliente o que pueda brindarle algún beneficio.
                    Estos correos electrónicos serán enviados a la dirección que el Cliente
                    proporcione y esta suscripción podrá ser cancelada en cualquier momento.
                </p>
                <p class="text-bold q-pl-lg">
                    La Empresa está altamente comprometida con mantener la información del Cliente
                    segura. La Empresa usa los sistemas más avanzados y los actualiza constantemente
                    para asegurarse que no exista ningún acceso no autorizado.
                </p>
                <p class="text-bold">iv. Cookies</p>
                <p class="text-bold q-pl-lg">
                    Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar
                    permiso para almacenarse en un ordenador. Al aceptar dicho fichero, el mismo se
                    crea, y la cookie cumple la función de tener información respecto al tráfico
                    web, y así facilitar las futuras visitas a la Plataforma. Con las cookies, la
                    Plataforma puede reconocer al Cliente de manera individual y por tanto brindarle
                    un mejor servicio personalizado.
                </p>
                <p class="text-bold q-pl-lg">
                    La Plataforma emplea las cookies para poder identificar las páginas que son
                    visitadas y su frecuencia. Esta información es empleada únicamente para análisis
                    estadístico y después la información se elimina de forma permanente. El Cliente
                    puede eliminar las cookies en cualquier momento desde su ordenador.
                </p>
                <p class="text-bold q-pl-lg">
                    Las cookies no dan acceso a información del Ordenador del Cliente, a menos de
                    que el Cliente así lo quiera y proporcione dicha información directamente. El
                    Cliente puede aceptar o negar el uso de cookies, sin embargo, la mayoría de los
                    navegadores aceptan cookies automáticamente. El Cliente puede declinar las
                    cookies de forma manual. Si se declinan, es posible que no pueda utilizar
                    algunos de los Servicios en la Plataforma.
                </p>
                <p class="text-bold">v. Enlaces a terceros</p>
                <p class="text-bold q-pl-lg">
                    La Plataforma pudiera contener enlaces a otros sitios web. Si el Cliente aprieta
                    dichos enlaces, y abandona la Plataforma, la Empresa no tiene control sobre el
                    sitio al cual el Cliente es redirigido, toda vez que es manejado por terceros.
                    Por tanto, la Empresa no es responsable por los términos y condiciones ni
                    políticas de privacidad con los que ese sitio web es manejado.
                </p>
                <p class="text-bold">vi. Control de su información personal</p>
                <p class="text-bold q-pl-lg">
                    En cualquier momento el Cliente puede restringir la recopilación o el uso de la
                    información personal que proporciona a nuestra Plataforma. El Cliente también
                    puede solicitarle a la Empresa que elimine todos los datos que la Empresa
                    mantiene sobre el Cliente. La Empresa eliminará dicha información de su base de
                    datos en 10 días calendario desde la fecha en que la solicitud fue hecha.
                </p>
                <p class="text-bold q-pl-lg">
                    Cuando la Plataforma le solicite a un Cliente rellenar un formulario, el Cliente
                    puede marcar o desmarcar la opción de recibir información de mercadeo por correo
                    electrónico. En caso de que haya marcado la opción de recibir los correos de la
                    Empresa, el Cliente podrá cancelarlo en cualquier momento.
                </p>
                <p class="text-bold">vii. Transferencia de Información a Terceros</p>
                <p class="text-bold q-pl-lg">
                    La Empresa no venderá, cederá, ni distribuirá la información personal que es
                    recopilada sin su consentimiento, salvo que sea requerido por un juez con un
                    orden judicial.
                </p>
                <p class="text-bold">viii. Modificación de Políticas de Privacidad</p>
                <p class="text-bold q-pl-lg">
                    La Empresa se reserva los derechos de cambiar o de modificar estos términos sin
                    previo aviso.
                </p>
            </div>
            <q-space />
        </div>
    </q-page>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0)
    },
}
</script>
