<template>
  <q-page class="q-pa-lg">
    <loading-alert :display="displayLoading"></loading-alert>
    <ygo-alert
      :display="displayAlert"
      :title="alertTitle"
      :message="alertMessage"
      :type="alertType"
      @accept="displayAlert = false"
    ></ygo-alert>
    <div>
      <div class="row q-mb-lg">
        <div class="text-h5">
          <div class="col q-px-md">Paquetes registrados</div>
        </div>
      </div>
      <div class="row q-mb-lg">
        <div class="col-lg-2 q-px-md">
          <q-input
            dense
            filled
            label="Casillero"
            ref="box"
            v-model="searchBox"
          />
        </div>
        <div class="col-lg-2 q-px-md">
          <q-input
            filled
            mask="date"
            label="Fecha inicial"
            dense
            v-model="dateToday"
          >
            <template v-slot:append>
              <q-icon name="fas fa-calendar" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    v-model="dateToday"
                    @input="() => $refs.qDateProxy.hide()"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                        v-close-popup
                        label="Cerrar"
                        color="primary"
                        flat
                      />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <div class="col-lg-2 q-px-md">
          <q-input
            filled
            mask="date"
            label="Fecha final"
            dense
            v-model="dateTomorow"
          >
            <template v-slot:append>
              <q-icon name="fas fa-calendar" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxyt"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    v-model="dateTomorow"
                    @input="() => $refs.qDateProxyt.hide()"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                        v-close-popup
                        label="Cerrar"
                        color="primary"
                        flat
                      />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <div class="col-lg-2 q-px-md">
          <q-input dense filled label="Tracking" v-model="searchTracking" />
        </div>

        <div class="col-lg-2 q-px-md">
          <q-input dense filled label="Factura" v-model="searchInvoice" />
        </div>
      </div>
      <div class="row q-mb-xl">
        <div class="col q-px-md">
          <q-table
            :data="filterTableData"
            :columns="packagesColumns"
            row-key="name"
            :pagination.sync="initialPagination"
            class="full-width my-sticky-header-table"
          >
            <template v-slot:header="props">
              <q-tr :props="props">
                <q-th
                  v-for="col in props.cols"
                  :key="col.name"
                  :props="props"
                  >{{ col.label }}</q-th
                >
                <!-- <q-th>Eliminar</q-th> -->
              </q-tr>
            </template>

            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td key="type" :props="props">
                  <q-icon
                    :name="
                      `fas fa-${
                        props.row.type === 'sea' ? 'ship' : 'plane-departure'
                      } text-primary`
                    "
                    size="sm"
                    class="q-mb-sm"
                  />
                </q-td>
                <q-td key="tracking" :props="props">
                  {{ props.row.tracking }}
                </q-td>
                <q-td key="box" :props="props">
                  {{ props.row.box }}
                </q-td>
                <q-td key="invoice" :props="props">
                  {{ props.row.invoice }}
                </q-td>
                <q-td key="dimensions" :props="props">
                  {{ returnDimensions(props.row) }}
                </q-td>
                <q-td key="weight" :props="props">
                  {{ props.row.weight }}
                </q-td>
                <q-td key="volumetricWeight" :props="props">
                  {{ props.row.volumetricWeight }}
                </q-td>
                <q-td key="calculatedWeight" :props="props">
                  {{ props.row.calculatedWeight }}
                </q-td>
                <q-td key="price" :props="props">
                  $ {{ props.row.totalPrice }}
                  <q-tooltip
                    anchor="bottom middle"
                    self="top middle"
                    content-class="bg-primary"
                    :offset="[10, 10]"
                  >
                    <div class="text-subtitle2">
                      <strong>$ {{ props.row.price }}</strong>
                      - Costo
                    </div>
                    <div
                      class="text-subtitle2"
                      v-for="(addchrg, i) in props.row.aditionalCharges"
                      :key="i"
                    >
                      <strong>$ {{ addchrg.chargeAmount.toFixed(2) }}</strong>
                      - {{ addchrg.chargeName }}
                    </div>
                  </q-tooltip>
                </q-td>
                <q-td key="date" :props="props">
                  {{ returnFormattedDate(props.row.creationTime) }}
                </q-td>
                <q-td key="admin" :props="props">
                  {{ `${props.row.by.name} ${props.row.by.lastName}` }}
                </q-td>
                <q-td key="supplierInvoice" :props="props">
                  <q-chip>Ver</q-chip>
                  <q-tooltip
                    anchor="bottom middle"
                    self="top middle"
                    content-class="bg-primary"
                    :offset="[10, 10]"
                  >
                    <div class="text-subtitle2">
                      <strong>No. Factura: </strong>
                      {{ props.row.supplierInvoice }}
                    </div>
                    <div class="text-subtitle2">
                      <strong>Fecha. Factura: </strong>
                      {{ props.row.supplierInvoiceDate }}
                    </div>
                  </q-tooltip>
                </q-td>
                <!-- <q-td key="supplierInvoiceDate" :props="props">
                                    {{ props.row.supplierInvoiceDate }}
                                </q-td> -->
                <!-- <q-td auto-width>
                <q-btn size="sm" color="red-7" round dense icon="fas fa-times" />
              </q-td>-->
              </q-tr>
            </template>
          </q-table>
        </div>
      </div>
      <q-page-sticky position="bottom-right" :offset="[18, 18]">
        <q-btn fab icon="fas fa-boxes" color="accent" to="/package-register" />
      </q-page-sticky>
    </div>
  </q-page>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";

import * as api from "@/api/api";
import dates from "@/mixins/dates";

import moment from "moment";
export default {
  mixins: [dates],
  data() {
    return {
      displayLoading: false,
      displayAlert: false,
      displayConfirm: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "",
      searchTracking: "",
      searchBox: "",
      searchInvoice: "",
      invoices: [],
      invoicesRaw: [],
      dateToday: "",
      dateTomorow: "",
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsPerPage: 10,
      },
      packagesColumns: [
        {
          name: "type",
          align: "left",
          label: "Tipo",
          field: "type",
        },
        {
          name: "tracking",
          align: "left",
          label: "Tracking",
          field: "tracking",
        },
        {
          name: "box",
          align: "left",
          label: "Casillero",
          field: "box",
          sortable: true,
        },
        {
          name: "invoice",
          align: "left",
          label: "Factura",
          field: "invoice",
          sortable: true,
        },
        {
          name: "dimensions",
          align: "left",
          label: "Dimensiones LxHxA (plg)",
          field: "dimensions",
        },
        {
          name: "weight",
          align: "left",
          label: "Peso fisico (lb)",
          field: "weight",
          sortable: true,
        },
        {
          name: "volumetricWeight",
          align: "left",
          label: "Peso volumetrico (lb)",
          field: "volumetricWeight",
          sortable: true,
        },
        {
          name: "calculatedWeight",
          align: "left",
          label: "Peso calculado (lb)",
          field: "calculatedWeight",
          sortable: true,
        },
        {
          name: "price",
          align: "left",
          label: "Precio ($)",
          field: "price",
          sortable: true,
        },
        {
          name: "date",
          align: "left",
          label: "Recibido el",
          field: "date",
          sortable: true,
        },
        {
          name: "admin",
          align: "left",
          label: "Recibido por",
          field: "admin",
          sortable: true,
        },
        {
          name: "supplierInvoice",
          align: "left",
          label: "Info Proveedor",
        },
      ],
      packagesData: [],
      packagesDataRaw: [],
      filteredUserData: [],
    };
  },
  methods: {
    returnDimensions(row) {
      return `${row.long} x ${row.height} x ${row.width}`;
    },
    returnIfItemIsInDateRange(pckg) {
      if (this.dateToday != "" || this.dateTomorow != "") {
        let dataDate = moment(pckg.creationTime).format("YYYY/MM/DD");
        return (
          moment(dataDate).isSameOrAfter(this.dateToday) &&
          moment(dataDate).isSameOrBefore(this.dateTomorow)
        );
      }
      return true;
    },
    async getAllInvoices() {
      try {
        console.log("loading invoices...");
        const response = await api.returnAllInvoices();
        const invoices = await response.data.data;

        console.log("invoices loaded: ", invoices.length);

        invoices.forEach((invoice) => {
          invoice.packages.forEach((packageItem) => {
            const newPackage = Object.assign(packageItem, {
              invoice: invoice.No,
            });
            this.packagesData.push(newPackage);
          });
        });

        this.invoices = invoices;
      } catch (error) {
        console.error(error);
      }
    },
    // setReturnAllPackagesWithInvoice(data) {
    //   console.log("2 Data sin filtro de paquetes ==>", data);
    //   this.invoices.forEach((factura) => {
    //     factura.packages.forEach((paquetes) => {
    //       //console.log('los paquetes en las facturas =>', paquetes)
    //       var newobj = Object.assign(paquetes, { invoice: factura.No });
    //       this.packagesData.push(newobj);
    //     });
    //   });

    //   /*
    //         data.forEach(obj => {
    //                //obj lleva los paquetes
    //                //metemos todas las facturas MODIFICACION
    //             if (obj.invoice != null) {
    //                 try {
    //                    // obj.invoice = this.invoices.find(invoice => invoice.id === obj.invoice)?.No || 'N/A'
    //                     const result = this.invoices.filter(invoice => invoice.id === obj.invoice);
    //                     if(!result.length ===0)
    //                          console.log('Resultado del filtro ==>', result);
    //                     if (obj.invoice !== 'N/A'){
    //                         this.packagesData.push(obj)
    //                      //   this.packagesData.push(obj)
    //                     }
    //                     else{   /// MODIFICACION
    //                         obj.invoice = '1'
    //                         this.packagesData.push(obj)
    //                     }

    //                 } catch (error) {
    //                     console.log('Error here ===>', error)
    //                     console.log(`error in paquete: ${JSON.stringify(obj)}`)
    //                 }
    //             }

    //             else {
    //                 try {
    //                         obj.invoice = '2'
    //                         this.packagesData.push(obj)
    //                 } catch (error) {
    //                     console.log('Error here ===>', error)
    //                     console.log(`error in paquete: ${JSON.stringify(obj)}`)
    //                 }
    //             }

    //            // this.packagesData.push(obj)
    //            // console.log('the final content data: ===>', obj.data())
    //         })
    //         */
    //   console.log("the final data: ===>", this.packagesData);
    // },
  },
  computed: {
    filterTableData() {
      let data = [];
      this.packagesData.forEach((pckg) => {
        if (
          pckg.box.includes(this.searchBox) &&
          this.returnIfItemIsInDateRange(pckg) &&
          pckg.tracking
            .toLowerCase()
            .includes(this.searchTracking.toLowerCase()) &&
          pckg.invoice.toString(10).includes(this.searchInvoice)
        )
          data.push(pckg);
      });
      //return data;
      return data.sort((a, b) => parseInt(b.invoice) - parseInt(a.invoice)); /// modificacion
    },
  },
  watch: {
    packagesData(newValue, oldValue) {
      this.filteredUserData = newValue;
    },
  },
  mounted() {
    this.$refs.box.focus();

    this.getAllInvoices();
  },
};
</script>

<style>
.my-sticky-header-table {
  height: 70vh;
}
.q-table__top,
.q-table__bottom,
thead tr:first-child th {
  background-color: #fff;
}

thead tr th {
  position: sticky;
  z-index: 1;
}

thead tr:first-child th {
  top: 0;
}

.q-table--loading thead tr:last-child th {
  top: 48px;
}
</style>
