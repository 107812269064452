<template>
  <q-page class="q-pa-lg">
    <loading-alert :display="displayLoading"></loading-alert>
    <ygo-alert
      :display="displayAlert"
      :title="alertTitle"
      :message="alertMessage"
      :type="alertType"
      @accept="displayAlert = false"
      :redirect="redirect"
    ></ygo-alert>
    <confirm-dialog
      :display="displayConfirm"
      :title="alertTitle"
      :message="alertMessage"
      @accept="deletePackage"
      @cancel="displayConfirm = false"
    ></confirm-dialog>
    <div>
      <div class="row q-mb-lg">
        <div class="text-h5">
          <div class="col q-px-md">Registro de paquetes</div>
        </div>
      </div>
      <div class="row q-mb-lg">
        <div class="col-lg-3 q-px-md">
          <q-file filled v-model="uploadFile" label="Subir por archivo" dense>
            <template v-slot:prepend>
              <q-icon name="fas fa-paperclip" />
            </template>
            <template v-slot:after>
              <q-btn
                round
                dense
                flat
                color="primary"
                icon="fas fa-paper-plane"
                @click="importExcel()"
              />
            </template>
          </q-file>
        </div>
        <q-space />
        <div class="col-lg-1">
          <q-btn color="accent" push @click="updatePackageInCurrentTable()"
            ><i class="far fa-save on-left"></i>Guardar</q-btn
          >
        </div>
        <div class="col-lg-2 q-px-md">
          <q-btn
            color="primary"
            push
            class="full-width"
            @click="checkPackagesBeforeInvoiceGeneration()"
          >
            <i class="fas fa-mail-bulk on-left"></i>
            Guardar y enviar
          </q-btn>
        </div>
      </div>
      <div class="row" style="margin-bottom: 65px">
        <div class="col-lg-8 q-px-md">
          <q-tabs
            v-model="tab"
            dense
            class="text-grey"
            active-color="primary"
            indicator-color="primary"
            align="justify"
            narrow-indicator
          >
            <q-tab
              v-for="(item, index) in subsidiariesTabs"
              :key="index"
              :name="item.name"
            >
              {{ item.name }}
            </q-tab>
          </q-tabs>
          <q-separator></q-separator>
          <q-tab-panels v-model="tab" keep-alive>
            <q-tab-panel
              v-for="(item, index) in subsidiariesTabs"
              :key="index"
              :name="item.name"
            >
              <q-table
                title="Paquetes por facturar"
                :data="item.data"
                :columns="packagesColumns"
                row-key="id"
                :pagination.sync="initialPagination"
                class="full-width my-sticky-header-table"
                selection="multiple"
                :selected.sync="selectedPackage"
              >
                <template v-slot:header="props">
                  <q-tr :props="props">
                    <q-th>
                      <q-checkbox v-model="props.selected" />
                    </q-th>
                    <q-th
                      v-for="col in props.cols"
                      :key="col.name"
                      :props="props"
                      >{{ col.label }}</q-th
                    >
                    <q-th>Acciones</q-th>
                  </q-tr>
                </template>
                <template v-slot:body="props">
                  <q-tr
                    :props="props"
                    :class="
                      props.rowIndex == activeRowIndex ? 'bg-secondary' : ''
                    "
                  >
                    <q-td>
                      <q-checkbox dense v-model="props.selected" />
                    </q-td>
                    <q-td key="tracking" :props="props">
                      {{ props.row.tracking }}
                      <q-popup-edit
                        v-model="props.row.tracking"
                        @save="updatePackageWithChange(props.row)"
                        title="Editar tracking"
                        buttons
                      >
                        <q-input
                          type="text"
                          v-model="props.row.tracking"
                          dense
                          autofocus
                        />
                      </q-popup-edit>
                    </q-td>

                    <q-td key="box" :props="props">
                      {{ props.row.box }}
                      <q-popup-edit
                        v-model="props.row.box"
                        @save="updatePackageWithChange(props.row)"
                        title="Editar casillero"
                        buttons
                      >
                        <q-input
                          type="text"
                          v-model="props.row.box"
                          dense
                          autofocus
                        />
                      </q-popup-edit>
                    </q-td>
                    <q-td key="dimensions" :props="props">
                      {{ returnDimensions(props.row) }}
                    </q-td>
                    <q-td key="weight" :props="props">
                      {{ props.row.weight }}
                      <q-popup-edit
                        v-model="props.row.weight"
                        @save="updatePackageWithChange(props.row)"
                        title="Editar peso"
                        buttons
                      >
                        <q-input
                          type="text"
                          v-model="props.row.weight"
                          dense
                          autofocus
                        />
                      </q-popup-edit>
                    </q-td>
                    <q-td key="price" :props="props">
                      {{ props.row.price }}
                    </q-td>
                    <q-td key="additionalCharges" :props="props">
                      $
                      {{
                        calculateAdditionalChargesTotal(
                          props.row.aditionalCharges
                        )
                      }}
                      <q-tooltip
                        anchor="bottom middle"
                        self="top middle"
                        content-class="bg-primary"
                        :offset="[10, 10]"
                        v-if="props.row.aditionalCharges.length > 0"
                      >
                        <div
                          class="text-subtitle2"
                          v-for="(addchrg, i) in props.row.aditionalCharges"
                          :key="i"
                        >
                          <strong
                            >$ {{ addchrg.chargeAmount.toFixed(2) }}</strong
                          >
                          - {{ addchrg.chargeName }}
                        </div>
                      </q-tooltip>
                    </q-td>
                    <q-td key="type" :props="props">
                      <q-icon
                        :name="
                          `fas fa-${
                            props.row.type === 'air'
                              ? 'plane-departure'
                              : 'ship'
                          } text-primary`
                        "
                        size="sm"
                        class="q-mb-sm"
                      />
                    </q-td>
                    <q-td key="providerInvoice" :props="props">
                      <q-chip>Ver</q-chip>
                      <q-tooltip
                        anchor="bottom middle"
                        self="top middle"
                        content-class="bg-primary"
                        :offset="[10, 10]"
                      >
                        <div class="text-subtitle2">
                          <strong>No. Factura: </strong>
                          {{ props.row.supplierInvoice }}
                        </div>
                        <div class="text-subtitle2">
                          <strong>Fecha. Factura: </strong>
                          {{ props.row.supplierInvoiceDate }}
                        </div>
                      </q-tooltip>
                    </q-td>
                    <q-td auto-width>
                      <!-- <q-btn
                                                size="sm"
                                                color="red-7"
                                                round
                                                flat
                                                dense
                                                icon="fas fa-times"
                                                @click="
                                                    askForDeletePackage(props.row.id)
                                                "
                                            /> -->
                      <q-btn-group>
                        <q-btn
                          icon="fas fa-edit"
                          size="sm"
                          round
                          flat
                          color="primary"
                          @click="populateForm(props.row, props.rowIndex)"
                        />
                        <q-btn
                          icon="fas fa-times"
                          size="sm"
                          round
                          flat
                          color="red-7"
                          @click="askForDeletePackage(props.row.id)"
                        />
                      </q-btn-group>
                    </q-td>
                  </q-tr>
                </template>
              </q-table>
              <!-- <div class="q-mt-md">
                                Selected: {{ JSON.stringify(selectedPackage) }}
                            </div> -->
            </q-tab-panel>
          </q-tab-panels>
        </div>
        <div class="col-lg-4 q-px-md">
          <q-card class="full-width">
            <q-card-section>
              <div class="text-h6">
                {{
                  isEditingFile
                    ? "Actualizar paquete"
                    : "Registrar nuevo paquete"
                }}
              </div>
            </q-card-section>

            <q-card-section>
              <q-input
                filled
                v-model="form.tracking"
                ref="tracking"
                label="Tracking"
                class="q-mb-md"
                :rules="[(val) => !!val || 'El campo es obligatorio']"
              />
              <q-input
                filled
                v-model="form.box"
                label="Casillero"
                class="q-mb-md"
                :rules="[(val) => !!val || 'El campo es obligatorio']"
                ref="box"
                @keyup.enter="saveDataLocally()"
              />
              <q-select
                filled
                ref="type"
                label="Tipo"
                v-model="form.type"
                class="q-mb-md"
                :options="[
                  { label: 'Aereo', value: 'air' },
                  { label: 'Maritimo', value: 'sea' },
                ]"
                map-options
                emit-value
                :rules="[(val) => !!val || 'El campo es obligatorio']"
              />
              <q-input
                filled
                v-model="form.weight"
                ref="weight"
                type="number"
                label="Peso Real"
                class="q-mb-md"
                :rules="[(val) => !!val || 'El campo es obligatorio']"
                @keyup.enter="saveDataLocally()"
              />
              <q-input
                filled
                v-model="form.long"
                ref="long"
                type="number"
                label="Largo"
                class="q-mb-md"
                :rules="[(val) => !!val || 'El campo es obligatorio']"
                @keyup.enter="saveDataLocally()"
              />
              <q-input
                filled
                v-model="form.height"
                ref="height"
                type="number"
                label="Alto"
                class="q-mb-md"
                :rules="[(val) => !!val || 'El campo es obligatorio']"
                @keyup.enter="saveDataLocally()"
              />
              <q-input
                filled
                v-model="form.width"
                ref="width"
                type="number"
                label="Ancho"
                class="q-mb-md"
                :rules="[(val) => !!val || 'El campo es obligatorio']"
                @keyup.enter="saveDataLocally()"
              />
              <q-input
                filled
                label="No. factura de proveedor"
                ref="supplierInvoice"
                v-model="form.supplierInvoice"
                class="q-mb-md"
                :rules="[(val) => !!val || 'El campo es obligatorio']"
              />
              <q-input
                filled
                class="q-mb-md"
                label="Fecha de factura de proveedor"
                ref="supplierInvoiceDate"
                v-model="form.supplierInvoiceDate"
                :rules="[(val) => !!val || 'El campo es obligatorio']"
              >
                <template v-slot:append>
                  <q-icon name="fas fa-calendar" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="form.supplierInvoiceDate"
                        @input="() => $refs.qDateProxy.hide()"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Cerrar"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
              <q-input
                filled
                type="number"
                label="Cargos adicionales"
                readonly
                class="q-mb-md"
                :value="calculateAdditionalChargesTotal(form.aditionalCharges)"
              >
                <template v-slot:after>
                  <q-btn
                    round
                    dense
                    flat
                    color="primary"
                    icon="far fa-list-alt"
                    @click="additionalChargesDialog = true"
                  />
                </template>
              </q-input>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
              <q-btn flat color="warning" @click="clear()">Cancelar</q-btn>
              <q-btn
                v-if="isEditingFile"
                flat
                color="primary"
                @click="saveDataLocally()"
                label="Siguiente"
              />
              <q-btn
                v-if="!isEditingFile"
                flat
                color="primary"
                @click="Generate()"
                >Registrar</q-btn
              >
            </q-card-actions>
          </q-card>
        </div>
      </div>
      <q-dialog v-model="additionalChargesDialog" persistent>
        <q-card style="width: 700px; max-width: 80vw">
          <q-card-section>
            <div class="text-h6">Cargos adicionales</div>
          </q-card-section>
          <q-card-section>
            <q-table
              :data="form.aditionalCharges"
              :columns="additionalChargesColumns"
              row-key="name"
              :pagination.sync="initialPagination"
              class="full-width"
            >
              <template v-slot:header="props">
                <q-tr :props="props">
                  <q-th
                    v-for="col in props.cols"
                    :key="col.name"
                    :props="props"
                    >{{ col.label }}</q-th
                  >
                  <q-th>Eliminar</q-th>
                </q-tr>
              </template>

              <template v-slot:body="props">
                <q-tr :props="props">
                  <q-td key="chargeName" :props="props">{{
                    props.row.chargeName
                  }}</q-td>
                  <q-td key="chargeAmount" :props="props"
                    >$ {{ parseFloat(props.row.chargeAmount).toFixed(2) }}</q-td
                  >
                  <q-td auto-width>
                    <q-btn
                      size="sm"
                      color="red-7"
                      round
                      dense
                      icon="fas fa-times"
                      flat
                      @click="deleteCharge(props.row)"
                    />
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </q-card-section>
          <q-separator />
          <q-card-section>
            <div class="text-h6">Agregar cargo</div>
          </q-card-section>
          <q-card-section>
            <div class="row">
              <div class="col-6">
                <q-input
                  filled
                  dense
                  label="Descripcion"
                  class="on-left"
                  v-model="chargeName"
                />
              </div>
              <div class="col-6">
                <q-input
                  filled
                  dense
                  label="Monto"
                  type="number"
                  class="on-right"
                  v-model.number="chargeAmount"
                />
              </div>
            </div>
          </q-card-section>

          <q-card-actions align="right" class="text-primary">
            <q-btn flat color="red-7" label="Cerrar" v-close-popup />
            <q-btn flat label="Agregar" @click="addToAdditionalCharges()" />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <!-- <q-page-sticky position="bottom-right" :offset="[18, 18]">
                <q-btn fab icon="fas fa-file-alt" color="accent"  />
            </q-page-sticky> -->
    </div>
    <q-dialog v-model="subsidiaryDialog" persistent>
      <q-card>
        <q-card-section>
          <div class="text-h6 text-center">Seleccione sucursal a enviar</div>
        </q-card-section>
        <q-card-section>
          <div v-for="(item, i) in subsidiariesNames" :key="i">
            <q-btn
              :label="item.name"
              color="primary"
              class="full-width q-mb-md"
              push
              @click="generateInvoiceBySubsidiary(item.id)"
              v-if="i % 2 === 0 && i !== subsidiariesNames.length - 1"
            />

            <q-btn
              :label="item.name"
              color="accent"
              class="full-width q-mb-md"
              push
              @click="generateInvoiceBySubsidiary(item.id)"
              v-else-if="i % 2 !== 0 && i !== subsidiariesNames.length - 1"
            />

            <q-btn
              :label="item.name"
              color="primary"
              class="full-width"
              push
              @click="generateInvoiceBySubsidiary(item.id)"
              v-else-if="i % 2 === 0 && i === subsidiariesNames.length - 1"
            />

            <q-btn
              :label="item.name"
              color="accent"
              class="full-width"
              push
              @click="generateInvoiceBySubsidiary(item.id)"
              v-else
            />
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            label="Cancelar"
            color="primary"
            v-close-popup
            @click="displayLoading = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import SubsidiariesNames from "@/enums/subsidiariesNames";
import xlsx from "xlsx";
import * as api from "@/api/api";
import "firebase/firestore";

export default {
  data() {
    return {
      activeRowIndex: null,
      additionalChargesDialog: false,
      uploadFile: null,
      displayLoading: false,
      displayAlert: false,
      displayConfirm: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "",
      chargeName: "",
      chargeAmount: "",
      redirect: "",
      updatedDatabase: false,
      form: {
        tracking: "",
        box: "",
        type: "air",
        weight: "",
        long: "",
        height: "",
        width: "",
        supplierInvoice: "",
        supplierInvoiceDate: "",
        aditionalCharges: [],
      },
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsPerPage: 0,
      },
      packagesColumns: [
        {
          name: "tracking",
          align: "left",
          label: "Tracking",
          field: "tracking",
        },
        {
          name: "box",
          align: "left",
          label: "Casillero",
          field: "box",
          sortable: true,
        },
        {
          name: "dimensions",
          align: "left",
          label: "Dimensiones LxHxA (plg)",
          field: "dimensions",
        },
        {
          name: "weight",
          align: "left",
          label: "Peso fisico (lb)",
          field: "weight",
          sortable: true,
        },
        // {
        //     name: 'price',
        //     align: 'left',
        //     label: 'Precio',
        //     field: 'price',
        //     sortable: true,
        // },
        {
          name: "additionalCharges",
          align: "left",
          label: "Cargos adicionales",
          field: "additionalCharges",
          sortable: true,
        },
        {
          name: "type",
          align: "left",
          label: "Tipo",
          field: "type",
          sortable: true,
        },
        {
          name: "providerInvoice",
          align: "left",
          label: "Info Proveedor",
        },
      ],
      packagesData: [],
      packagesLoaded: false,
      workingDeletedId: "",
      usersRegistered: [],
      usersBox: [],
      isEditingFile: false,
      additionalChargesColumns: [
        {
          name: "chargeName",
          align: "left",
          label: "Descripcion",
          field: "chargeName",
        },
        {
          name: "chargeAmount",
          align: "left",
          label: "Monto",
          field: "chargeAmount",
        },
      ],
      subsidiaryDialog: false,
      subsidiariesNames: [],
      subsidiariesTabs: [],
      tab: "Todos",
      selectedPackage: [],
    };
  },
  computed: {
    filteredPackagesData() {
      if (!this.packagesLoaded) return [];
      return this.packagesData.filter((package1) => {
        const user = this.usersRegistered.find(
          (user) => user.box === package1.box
        );
        if (
          package1.invoice === null &&
          typeof user?.subsidiary !== "undefined"
        ) {
          package1.subsidiary = user.subsidiary;
        }

        return package1;
      });
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    importExcel() {
      if (this.uploadFile === null) {
        this.alertTitle = "Error";
        this.alertMessage = "Tiene que selecionar un archivo primero";
        this.alertType = "error";
        this.displayAlert = true;
        return;
      }
      this.displayLoading = true;
      this.displayAlert = false;
      const files = this.uploadFile;
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            raw: false,
          });
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            ws[i].box = this.validateBox(ws[i].box);
            ws[i].by = this.user;
            ws[i].type = ws[i].type ? ws[i].type.toLowerCase() : "air";
            ws[i].long = ws[i].long || 1;
            ws[i].height = ws[i].height || 1;
            ws[i].width = ws[i].width || 1;
            excellist.push(ws[i]);
          }
          api
            .createPackagesFromXls({ packages: excellist })
            .then(() => {
              this.uploadFile = null;
              this.displayLoading = false;
              this.alertTitle = "Exito!";
              this.alertMessage = "Se ha actualizado con exito";
              this.alertType = "success";
              this.displayAlert = true;
              this.checkIfErrors(excellist);
            })
            .catch((e) => {
              console.log(e);
              this.displayLoading = false;
              this.alertTitle = "Error";
              this.alertMessage = "Hubo un error con la peticion";
              this.alertType = "error";
              this.displayAlert = true;
            });
        } catch (e) {
          console.log(e);
          alert("Read failure!");
          this.displayLoading = false;
        }
      };
      fileReader.readAsBinaryString(files);
    },
    async getAllPackagesWithoutInvoice() {
      try {
        console.log("loading packages...");
        const response = await api.ReturnAllPackagesWithoutInvoice();

        this.packagesData = response.data.data;
        this.packagesLoaded = true;

        await this.getAllUsersAndBoxes();
      } catch (error) {
        console.error(error);
      }
    },
    async getAllUsersAndBoxes() {
      try {
        const response = await api.ReturnAllUsers();
        this.usersRegistered = response.data.data;
        this.usersBox = this.usersRegistered.map((user) => user.box);

        if (this.packagesData) {
          this.loadPackageData();
          console.log("packages loaded: ", this.packagesData.length);
        }
      } catch (error) {
        console.error(error);
      }
    },
    updatePackage() {
      this.displayLoading = true;
      this.displayAlert = false;
      let id = this.form.id;
      delete this.form.id;
      api
        .UpdatePackageInformationById({
          id: id,
          package: this.form,
        })
        .then(() => {
          this.displayLoading = false;
          this.alertTitle = "Exito!";
          this.alertMessage = "Se ha actualizado con exito";
          this.alertType = "success";
          this.displayAlert = true;
          this.clear();
        })
        .catch((error) => {
          console.log(error);
          this.displayLoading = false;
          this.alertTitle = "Error";
          this.alertMessage = "Hubo un error con la peticion";
          this.alertType = "error";
          this.displayAlert = true;
        });
    },
    deleteCharge(currentSelectedCharge) {
      this.form.aditionalCharges.forEach((charges, index) => {
        if (
          charges.chargeName === currentSelectedCharge.chargeName &&
          charges.chargeAmount === currentSelectedCharge.chargeAmount
        ) {
          this.form.aditionalCharges.splice(index, 1);
        }
      });
    },
    addToAdditionalCharges() {
      if (this.chargeName === "" || this.chargeAmount === "") {
        this.displayLoading = false;
        this.alertTitle = "Error";
        this.alertMessage =
          "Por favor asegurate que llenaste los datos de nombre y precio correctamente";
        this.alertType = "error";
        this.displayAlert = true;
        return;
      }
      this.form.aditionalCharges.push({
        chargeName: this.chargeName,
        chargeAmount: this.chargeAmount,
      });
      this.displayLoading = false;
      this.alertTitle = "Exito!";
      this.alertMessage = "Se ha creado con exito la tarifa";
      this.alertType = "success";
      this.displayAlert = true;
      this.chargeName = "";
      this.chargeAmount = "";
    },
    populateForm(selectedPackage, row) {
      this.isEditingFile = true;
      this.form = {
        id: selectedPackage.id,
        tracking: selectedPackage.tracking,
        type: selectedPackage.type,
        box: selectedPackage.box,
        weight: selectedPackage.weight,
        long: selectedPackage.long,
        height: selectedPackage.height,
        width: selectedPackage.width,
        supplierInvoice: selectedPackage.supplierInvoice,
        supplierInvoiceDate: selectedPackage.supplierInvoiceDate,
        aditionalCharges: selectedPackage.aditionalCharges,
      };
      this.activeRowIndex = row;
      this.$refs.box.focus();
    },
    async checkIfEmpty() {
      let isEmpty = false;
      for await (const filterPackage of this.filteredPackagesData) {
        if (
          filterPackage.box === null ||
          filterPackage.tracking === null ||
          filterPackage.weight === null ||
          filterPackage.long === null ||
          filterPackage.height === null ||
          filterPackage.width === null ||
          filterPackage.supplierInvoice === null ||
          filterPackage.supplierInvoiceDate === null
        ) {
          isEmpty = true;
        }
      }
      return isEmpty;
    },
    async checkIfErrors(packages) {
      let errors = [];

      packages.forEach((pkg) => {
        if (!this.usersBox.includes(pkg.box)) {
          console.log(`se encontro un error ${pkg.box}`);
          errors.push(pkg.box);
        }
      });
      if (errors.length > 0) {
        this.alertTitle = "Error";
        this.alertMessage =
          "Por favor verificar los siguientes errores: " + errors.join(", ");
        this.alertType = "error";
        this.displayAlert = true;
        return true;
      }
      return false;
    },
    async checkPackagesBeforeInvoiceGeneration() {
      this.displayLoading = true;
      this.displayAlert = false;
      if (await this.checkIfEmpty()) {
        this.displayLoading = false;
        this.alertTitle = "Error";
        this.alertMessage =
          "Por favor los campos no puede estar ninguno vacio.";
        this.alertType = "error";
        this.displayAlert = true;
        return;
      }
      if (await this.checkIfErrors(this.filteredPackagesData)) {
        this.subsidiaryDialog = false;
        this.displayLoading = false;
        return;
      }
      this.subsidiaryDialog = true;
    },
    generateInvoiceBySubsidiary(subsidiary) {
      this.selectedPackage.forEach((pckg, index) => {
        let user = this.usersRegistered.find((user) => user.box === pckg.box);
        this.selectedPackage[index].subsidiary = user.subsidiary;
      });
      let filteredPackagesBySelectedSubsidiary = this.selectedPackage.filter(
        (pckg) => pckg.subsidiary === subsidiary
      );
      this.subsidiaryDialog = false;
      if (filteredPackagesBySelectedSubsidiary.length <= 0) {
        this.displayLoading = false;
        this.alertTitle = "Hubo un Error!";
        this.alertMessage =
          "No hay ningun paquete pendiente para esta subsidiaria";
        this.alertType = "error";
        this.displayAlert = true;
        return;
      }
      api
        .UpdateGroupPackages({
          packages: this.selectedPackage,
        })
        .then(() => {
          api
            .CreateInvoiceOnDatabase({
              by: this.user,
              subsidiary: subsidiary,
            })
            .then(() => {
              this.displayLoading = false;
              this.alertTitle = "Exito!";
              this.alertMessage =
                "Hemos generado todas las facturas correspondientes.";
              this.alertType = "success";
              this.displayAlert = true;
              this.redirect = "/invoice-manager";
            })
            .catch((error) => {
              this.displayLoading = false;
              console.log(error);
              this.alertTitle = "Hubo un Error!";
              this.alertMessage =
                "Hubo un error con tu peticion por favor intentalo mas tarde.";
              this.alertType = "error";
              this.displayAlert = true;
            });
        })
        .catch((error) => {
          console.log(error);
          this.displayLoading = false;
          this.alertTitle = "Error";
          this.alertMessage = error;
          this.alertType = "error";
          this.displayAlert = true;
        });
    },
    updatePackageInCurrentTable() {
      this.displayLoading = true;
      this.displayAlert = false;
      api
        .UpdateGroupPackages({
          packages: this.filteredPackagesData,
        })
        .then(() => {
          this.updatePackage();
          this.displayLoading = false;
          this.alertTitle = "Exito!";
          this.alertMessage = "Se ha cambiado con exito";
          this.alertType = "success";
          this.displayAlert = true;
          this.updatedDatabase = true;
          this.checkIfErrors(this.filteredPackagesData);
        })
        .catch((error) => {
          console.log(error);
          this.displayLoading = false;
          this.alertTitle = "Error";
          this.alertMessage = error;
          this.alertType = "error";
          this.displayAlert = true;
        });
    },
    updatePackageWithChange(packages) {
      this.displayLoading = true;
      this.displayAlert = false;
      api
        .UpdatePackageInformationById({
          id: packages.id,
          package: packages,
        })
        .then(() => {
          this.displayLoading = false;
          this.alertTitle = "Exito!";
          this.alertMessage = "Se ha guardado con exito";
          this.alertType = "success";
          this.displayAlert = true;
        })
        .catch((error) => {
          console.log(error);
          this.displayLoading = false;
          this.alertTitle = "Error";
          this.alertMessage = error;
          this.alertType = "error";
          this.displayAlert = true;
        });
    },
    validateBox(input) {
      if (input && input.length > 0) {
        input = input.trim();
        input = input.replaceAll(" ", "");
        input = input.replaceAll(/[^A-Za-z0-9]/g, "");
        if (input.includes("YGO")) {
          return input;
        }
        return `YGO${input}`;
      }
      return input;
    },
    deletePackage() {
      this.displayLoading = true;
      this.displayAlert = false;
      this.displayConfirm = false;
      api
        .DeletePackageOnDatabase({
          id: this.workingDeletedId,
        })
        .then(() => {
          this.displayLoading = false;
          this.alertTitle = "Exito!";
          this.alertMessage = "Se ha eliminado el paquete con exito";
          this.alertType = "success";
          this.displayAlert = true;
        })
        .catch((error) => {
          console.log(error);
          this.displayLoading = false;
          this.alertTitle = "Error";
          this.alertMessage = error;
          this.alertType = "error";
          this.displayAlert = true;
        });
    },
    askForDeletePackage(id) {
      this.displayConfirm = true;
      this.alertTitle = "Esta seguro?";
      this.alertMessage = "Se va a proceder a eliminar este paquete";
      this.workingDeletedId = id;
    },
    clear() {
      this.form.tracking = "";
      this.form.box = "";
      this.form.type = "air";
      this.form.weight = "";
      this.form.long = "";
      this.form.height = "";
      this.form.width = "";
      this.form.supplierInvoice = "";
      this.form.supplierInvoiceDate = "";
      this.form.aditionalCharges = [];
      this.isEditingFile = false;
      this.activeRowIndex = null;
      this.resetFormValidations();
    },
    async Generate() {
      this.displayLoading = true;
      this.form.by = this.user;
      this.form.box = await this.validateBox(this.form.box);
      if (this.usersBox.includes(this.form.box)) {
        api
          .CreatePackageOnDatabase({ package: this.form })
          .then((response) => {
            this.clear();
            this.displayLoading = false;
            this.alertTitle = "Exito!";
            this.alertMessage = "Se ha creado el paquete con exito";
            this.alertType = "success";
            this.displayAlert = true;
          })
          .catch((error) => {
            console.log(error);
            this.displayLoading = false;
            this.alertTitle = "Error";
            this.alertMessage = "Hubo un error por favor vuelve a intentarlo";
            this.alertType = "error";
            this.displayAlert = true;
          });
      } else {
        this.displayLoading = false;
        this.alertTitle = "Error";
        this.alertMessage =
          "El usuario casillero ingresado no existe en la base de datos por favor revisar";
        this.alertType = "error";
        this.displayAlert = true;
      }
    },
    resetFormValidations() {
      setTimeout(() => {
        this.$refs.tracking.resetValidation();
        this.$refs.box.resetValidation();
        this.$refs.type.resetValidation();
        this.$refs.weight.resetValidation();
        this.$refs.long.resetValidation();
        this.$refs.height.resetValidation();
        this.$refs.width.resetValidation();
        this.$refs.supplierInvoice.resetValidation();
        this.$refs.supplierInvoiceDate.resetValidation();
      }, 1);
    },
    addToData(id, data) {
      data.id = id;
      this.packagesData.push(data);
    },
    editData(id, data) {
      data.id = id;
      this.packagesData.forEach((d, index) => {
        if (d.id === id) {
          this.packagesData.splice(index, 1, data);
        }
      });
    },
    removeData(id) {
      this.packagesData.forEach((d, index) => {
        if (d.id === id) {
          this.packagesData.splice(index, 1);
        }
      });
    },
    returnDimensions(row) {
      return `${row.long} x ${row.height} x ${row.width}`;
    },
    calculateAdditionalChargesTotal(additionalCharges) {
      try {
        let total = 0;
        additionalCharges.forEach((el) => {
          total += el.chargeAmount;
        });
        return parseFloat(total).toFixed(2);
      } catch (error) {
        console.log(error);
        return "NaN";
      }
    },
    async updateTable() {
      this.form.box = await this.validateBox(this.form.box);
      if (!this.usersBox.includes(this.form.box)) {
        this.displayLoading = false;
        this.alertTitle = "Error";
        this.alertMessage =
          "El usuario casillero ingresado no existe en la base de datos por favor revisar";
        this.alertType = "error";
        this.displayAlert = true;
        return;
      }
      let index = 0;
      this.filteredPackagesData.forEach((packagesData, i) => {
        if (packagesData.id === this.form.id) {
          index = i;
        }
      });
      this.$set(
        this.filteredPackagesData[index],
        "tracking",
        this.form.tracking
      );
      this.$set(this.filteredPackagesData[index], "box", this.form.box);
      this.$set(this.filteredPackagesData[index], "weight", this.form.weight);
      this.$set(this.filteredPackagesData[index], "long", this.form.long);
      this.$set(this.filteredPackagesData[index], "height", this.form.height);
      this.$set(this.filteredPackagesData[index], "width", this.form.width);
    },
    async saveDataLocally() {
      if (this.isEditingFile) {
        await this.updateTable();
        this.activeRowIndex++;
        if (this.activeRowIndex < this.filteredPackagesData.length) {
          this.populateForm(
            this.filteredPackagesData[this.activeRowIndex],
            this.activeRowIndex
          );
        } else {
          this.displayAlert = true;
          this.alertTitle = "Finalizado";
          this.alertMessage = "Has llegado al final de la lista.";
          this.alertType = "success";
          this.clear();
        }
      }
    },
    loadPackageData() {
      this.subsidiariesNames = SubsidiariesNames;
      let prevTabs = [{ name: "Todos" }, ...this.subsidiariesNames];
      this.subsidiariesTabs = prevTabs.map((prevTab, index) => {
        prevTab.data =
          index === 0
            ? this.filteredPackagesData
            : this.filteredPackagesData.filter(
                (data) => data.subsidiary === prevTab.id
              );
        return prevTab;
      });
    },
  },
  mounted() {
    this.getAllPackagesWithoutInvoice();
  },
};
</script>
