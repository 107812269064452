<template>
    <q-layout class="y-go-font">
        <!-- NAVIGATION BAR -->   
        <div v-if="pagina_origen == 'life'" > 
        <Navbar class="xs-hide sticky-header" :navLinks="navLinks" :pagina_origen="pagina_origen" />
        <NavbarMobile class="sm-hide md-hide lg-hide xl-hide sticky-header" :navLinks="navLinks" :pagina_origen="pagina_origen" />
        </div>  
        <div v-else > 
        <Navbar class="xs-hide sticky-header" :navLinks="navLinks" />
        <NavbarMobile class="sm-hide md-hide lg-hide xl-hide sticky-header" :navLinks="navLinks"  />
        </div>   
        <!-- END NAVIGATION BAR -->
        <q-page-container>
            <router-view />
            <q-page-sticky position="bottom-right" :offset="[30, 30]">
                <a href="https://wa.me/50767210200" target="_blank" style="text-decoration: none;">
                    <q-btn fab icon="fab fa-whatsapp" color="accent" push />
                </a>
            </q-page-sticky>
        </q-page-container>
        <!-- FOOTER -->
        <div v-if="pagina_origen != 'life'" >
        <Footer />
        </div>
        <!-- END FOOTER -->
    </q-layout>
</template>

<script>
import Navbar from '@/components/general/Navbar'
import NavbarMobile from '@/components/general/NavbarMobile'
import Footer from '@/components/general/Footer'

export default {
    data() {
        return {
            navLinks: [
                {
                    label: 'INICIO',
                    route: '/',
                },
                {
                    label: 'QUIENES SOMOS',
                    route: '/quienes-somos',
                },
                {
                    label: 'PLANES',
                    route: '/planes',
                },
                {
                    label: 'PROGRAMA DE LEALTAD',
                    route: '/programa-de-lealtad',
                },
                {
                    label: 'Y-GA Y RECICLA',
                    route: '/yga-y-recicla',
                },
                {
                    label: 'CONTÁCTANOS',
                    route: '/contactanos',
                },
            ],
            pagina_origen: new URL(location.href).searchParams.get('page'),
        }
    },
    components: {
        Navbar,
        Footer,
        NavbarMobile,
    },
}
</script>

<style>
@import './../styles/style.css';
</style>
