<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import * as api from '@/api/api'
export default {
    computed: {
        uid() {
            return this.$store.getters.uid
        },
        user() {
            return this.$store.getters.user
        },
    },
    async mounted() {
        if (this.$store.getters.uid !== '' || !!this.$store.getters.user) {
            let currentUser = await api
                .returnUserProfileInformation({uid: this.uid})
                .then(resp => resp.data.data)

            this.$store.commit('SET_USER', currentUser.user)
        }
    },
}
</script>
