import { render, staticRenderFns } from "./Landing.vue?vue&type=template&id=b84a19fc&"
import script from "./Landing.vue?vue&type=script&lang=js&"
export * from "./Landing.vue?vue&type=script&lang=js&"
import style0 from "./Landing.vue?vue&type=style&index=0&id=b84a19fc&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QPageContainer,QPageSticky,QBtn});
