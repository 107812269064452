import { render, staticRenderFns } from "./UserProfile.vue?vue&type=template&id=0024e594&"
import script from "./UserProfile.vue?vue&type=script&lang=js&"
export * from "./UserProfile.vue?vue&type=script&lang=js&"
import style0 from "./UserProfile.vue?vue&type=style&index=0&id=0024e594&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QTab,QTabPanels,QTabPanel,QInput,QSelect,QIcon,QPopupProxy,QDate,QBtn,QSeparator,QList,QExpansionItem,QCard,QCardSection,QForm});qInstall(component, 'directives', {ClosePopup});
