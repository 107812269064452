<template>
    <div class="y-go-font">
        <div class="row">
            <div class="col-3 q-pa-md">
                <q-img :src="require('@/assets/logo_ygo.png')" />
            </div>
            <q-space />

            <div class="col-3 q-pa-md text-right">
                <div class="text-h6 text-bold text-accent">
                    {{ returnFormattedDate(invoiceData.creationTime) }}
                </div>
                <div class="text-h6 text-bold text-accent">
                    {{
                        invoiceData.status == 'paid' || invoiceData.status == 'delivered'
                            ? 'FACTURA'
                            : 'PROFORMA'
                    }}
                </div>
                <div class="row justify-end items-center">
                    <div class="arrow-left" />
                    <div
                        class="col-7 text-h5 bg-accent q-py-xs q-pr-sm"
                        style="height: 40px; font-weight: 700; color: #fff"
                    >
                        {{ invoiceData.No }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 q-pa-md">
                <div class="text-subtitle2 text-accent">Y-GO S.A.</div>
                <div class="text-subtitle2 text-accent">T. 507 258 8888</div>
                <div class="text-subtitle2 text-accent">RUC 7497438948938-97843</div>
                <div class="text-subtitle2 text-accent">DV 56</div>
            </div>
            <q-space />
            <div class="col-6 q-pa-md text-right">
                <div class="text-subtitle2 text-accent">Provincia, Distrito</div>
                <div class="text-subtitle2 text-accent">Corregimiento Barrio</div>
                <div class="text-subtitle2 text-accent">Calle, Local numero A</div>
            </div>
        </div>
        <div class="row text-white bg-primary">
            <div class="col-6 q-px-md q-py-sm">
                <div class="text-subtitle2">
                    Cliente: <span class="text-bold">{{ user.name }} {{ user.lastName }}</span>
                </div>
            </div>
            <q-space />
            <div class="col-6 text-right q-px-md q-py-sm">
                <div class="text-subtitle2">
                    Casillero: <span class="text-bold">{{ user.box }}</span>
                </div>
            </div>
        </div>
        <div class="row text-primary bg-secondary" v-if="ruc && dv">
            <div class="col-6 q-px-md q-py-sm">
                <div class="text-subtitle2">
                    CIA: <span class="text-bold">{{ business }}</span>
                </div>
            </div>
            <q-space />
            <div class="col-6 text-right q-px-md q-py-sm">
                <div class="text-subtitle2">
                    RUC: <span class="text-bold">{{ ruc }}</span> DV:
                    <span class="text-bold">{{ dv }}</span>
                </div>
            </div>
        </div>
        <div class="q-pa-lg">
            <div class="row text-primary text-bold text-center q-py-sm">
                <div class="col-5 text-left">TRACKING</div>
                <div class="col-3">DIMENSIONES</div>
                <div class="col-3">PESO</div>
                <div class="col-1">SUBTOTAL</div>
            </div>
            <div class="row text-primary text-center q-py-xs">
                <div class="col-5" />
                <div class="col-1">ancho</div>
                <div class="col-1">largo</div>
                <div class="col-1">alto</div>
                <!-- <div class="col-1">real</div>
                <div class="col-1">volumétrico</div>
                <div class="col-1">pie3</div> -->
            </div>
            <q-separator color="primary" />
            <div
                class="row text-center q-py-lg"
                v-for="pckg in invoiceData.packages"
                :key="pckg.tracking"
            >
                <div class="col-5 text-left">
                    {{ pckg.tracking }}
                </div>
                <div class="col-1">
                    {{ pckg.width }}
                </div>
                <div class="col-1">
                    {{ pckg.long }}
                </div>
                <div class="col-1">
                    {{ pckg.height }}
                </div>
                <!-- <div class="col-1">
                    xxx lb
                </div> -->
                <!-- <div class="col-1">
                    xxx lb
                </div> -->
                <div class="col-3">
                    {{ pckg.calculatedWeight }} {{ pckg.type == 'air' ? 'lb' : 'pie3' }}
                </div>
                <div class="col-1">$ {{ pckg.price }}</div>
            </div>
            <q-separator color="primary" />
            <div class="row q-py-md">
                <q-space />
                <div class="col-3 text-bold text-primary">
                    <div class="q-py-xs">DELIVERY</div>
                    <div class="q-py-xs">DESCUENTO</div>
                    <div class="q-py-xs">ITBMS</div>
                    <div class="q-py-xs">CARGOS ADICIONALES</div>
                    <div class="q-py-xs text-black">TOTAL</div>
                </div>
                <div class="col-2 text-right">
                    <div class="q-py-xs">$ 0.00</div>
                    <div class="q-py-xs">$ 0.00</div>
                    <div class="q-py-xs">$ 0.00</div>
                    <div class="q-py-xs">$ {{ calculateTotalAdditionalCharges() }}</div>
                    <div class="q-py-xs">$ {{ invoiceData.price }}</div>
                </div>
            </div>
        </div>
        <div class="bg-accent text-white text-center text-bold q-py-sm">
            y-go.com.pa / cuentas@y-go.com.pa
        </div>
    </div>
</template>

<script>
import dates from '@/mixins/dates'
export default {
    mixins: [dates],
    props: ['invoiceData', 'ruc', 'dv', 'business', 'user'],
    methods: {
        calculateTotalAdditionalCharges() {
            let total = 0
            this.invoiceData.packages.forEach(pckg => {
                pckg.aditionalCharges.forEach(charge => {
                    total += charge.chargeAmount
                })
            })
            return total.toFixed(2)
        },
    },
}
</script>

<style>
.arrow-left {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;

    border-right: 12px solid #ff5722;
}
</style>
