<template>


    <q-card>
        <div class="pattern-bg">
            <div class="row">
                <div class="col desktop-only"></div>
                <div class="col-lg-7 y-go-font">
                    <div  v-if="registrationData.absorbedCompany!='LifeLine'" class="text-center q-py-lg q-mb-md">
                        <q-img :src="require('@/assets/logo_ygo.png')" style="width: 150px" />
                    </div>
                    <div  v-else class="text-center q-py-lg q-mb-md">
                        
                    </div>
                    <q-form @submit="checkSteppers()" class="q-px-md">
                        <q-stepper
                            v-model="step"
                            ref="stepper"
                            color="primary"
                            animated
                            :vertical="
                                $q.screen.name == 'sm' || $q.screen.name == 'xs' ? true : false
                            "
                            style="border-top: solid 5px #ff5722"
                            class="q-mb-xl"
                        >
                            <q-step :name="1" title="Información personal" :done="step > 1">
                                <div class="text-h4 text-center text-primary">
                                    <span class="text-bold text-secondary">1.</span> Información
                                    personal
                                </div>
                                <q-separator
                                    color="accent"
                                    style="
                                        width: 50%;
                                        margin: 0 auto;
                                        height: 2px;
                                        margin-bottom: 20px;
                                    "
                                />
                                <div class="row">
                                    <div class="col-lg-6 col-xs-12 q-pa-sm">
                                        <q-input
                                            filled
                                            class="y-go-1x"
                                            label="Nombre *"
                                            v-model="registrationData.name"
                                            :rules="[
                                                val => val.length > 0 || 'El campo es obligatorio',
                                            ]"
                                        />
                                    </div>
                                    <div class="col-lg-6 col-xs-12 q-pa-sm">
                                        <q-input
                                            filled
                                            label="Apellido *"
                                            class="y-go-1x"
                                            v-model="registrationData.lastName"
                                            :rules="[
                                                val => val.length > 0 || 'El campo es obligatorio',
                                            ]"
                                        />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col q-pa-sm">
                                      
                                            <q-input
                                                filled
                                                class="y-go-1x"
                                                label="Nombre de la compañía"
                                                v-model="registrationData.company"
                                            />
                                       
                                    </div>
                                </div>

                                <div class="row">
                                    <!--
                                    <div  style="min-height: 100px;">
                                    <div class="q-pa-md" >
                                    -->

 


                                
                                    <div class="col-lg-2 col-xs-12 q-pa-sm" >
                                        <q-select
                                            filled
                                            class="y-go-1x"
                                            label="País"
                                            behavior="dialog" 
                                            :options="countryCodes"
                                            @filter="filterFn"
                                            v-model="registrationData.countryCode"
                                            emit-value
                                        ></q-select>
                                    </div>
                             


                                <!--
                                    <div class="col-lg-2 col-xs-12 q-pa-sm" >
                                        <q-select
                                            filled
                                            class="y-go-1x"
                                            label="País"
                                            onfocus="this.size=10"
                                            :options="countryCodes"
                                            v-model="registrationData.countryCode"
                                            emit-value
                                        />
                                    </div>
                                -->

                                <!--
                                    <div id="q-app" style="min-height: 100vh;">
                                    <div class="q-pa-md" style="max-width: 300px">
                                        <div class="q-gutter-md">
                                        <q-select
                                            filled
                                            v-model="registrationData.countryCode"
                                            multiple
                                            :options="countryCodes"
                                        ></q-select>
                                        </div>
                                    </div>
                                    </div>
                                -->

                                    <div class="col-lg-10 col-xs-12 q-pa-sm">
                                        <q-input
                                            filled
                                            class="y-go-1x"
                                            label="Celular *"
                                            v-model="registrationData.phone"
                                            type="number"
                                            fill-mask
                                            :rules="[
                                                val => val.length > 0 || 'El campo es obligatorio',
                                            ]"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-xs-12 q-pa-sm">
                                        <q-input filled label="Indica tu Fecha de Nacimiento" v-model="registrationData.birthdate" mask="date" :rules="['date']">
                                            <template v-slot:append>
                                                <q-icon name="fas fa-calendar" class="cursor-pointer">
                                                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                                        <q-date v-model="registrationData.birthdate" minimal>
                                                            <div class="row items-center justify-end">
                                                                <q-btn v-close-popup label="Cerrar" color="primary" flat></q-btn>
                                                            </div>
                                                        </q-date>
                                                    </q-popup-proxy>
                                                </q-icon>
                                            </template>
                                        </q-input>
                                    </div>
                                </div>
                                <!--Agregado, pertenecia al paso siguiente-->
                                <div class="row">
                                    <div class="col q-pa-sm">
                                        <q-select
                                            filled
                                            class="y-go-1x"
                                            label="Sucursal de preferencia *"
                                            :options="Object.values(subsidiaries)"
                                            :rules="[
                                                val => val.label.length > 0 || 'El campo es obligatorio',
                                            ]"
                                            v-model="selectedSubsidiary"
                                        />
                                    </div>
                                </div>
                            </q-step>

                            <q-step
                                v-if="registrationData.absorbedCompany!='LifeLine'"
                                :name="2"
                                title="Escoge tu plan"
                                icon="fas fa-dollar-sign"
                                :done="step > 2"
                            >
                            
                                <div   v-if="registrationData.absorbedCompany!='LifeLine'" class="text-h4 text-center text-primary">
                                    <span class="text-bold text-secondary">2.</span> Escoge tu plan
                                </div>
                                <div   v-else  class="text-h4 text-center text-primary">
                                    <span class="text-bold text-secondary">2.</span> Tu plan
                                </div>
                                <q-separator
                                    color="accent"
                                    style="
                                        width: 50%;
                                        margin: 0 auto;
                                        height: 2px;
                                        margin-bottom: 20px;"
                                />
                                <!--    
                                <div class="row">
                                    <div class="col q-pa-sm">
                                        <q-select
                                            filled
                                            class="y-go-1x"
                                            label="Sucursal de preferencia *"
                                            :options="Object.values(subsidiaries)"
                                            :rules="[
                                                val => val.label.length > 0 || 'El campo es obligatorio',
                                            ]"
                                            v-model="selectedSubsidiary"
                                        />
                                    </div>
                                </div>
                                -->
                                <div class="row q-mb-md">
                                    <div   class="col-lg-4 col-xs-12 q-pa-sm"    >
                                        <div
                                            v-if="registrationData.absorbedCompany!='LifeLine'"
                                            :class="returnRateClass('PLAN BASICO')"
                                            @click="assingSelectedRate('PLAN BASICO')"
                                        >
                                            <i class="fas fa-box-open fa-2x q-mb-sm"></i>
                                            <div class="text-h6 q-mb-sm text-bold">
                                                PLAN STANDARD
                                            </div>
                                            <q-separator class="q-mb-sm" dark />
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                PO Box personal en Miami
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Carga aérea y carga maritima
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Vuelos diarios Miami - Panam&aacute;
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                <a
                                                    class="text-primary"
                                                    href="/terminos-y-condiciones#reclamos-y-seguros"
                                                    target="_blank"
                                                    >Paqueteria asegurada</a
                                                >
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Entrega a domicilio (ciudad y provincia)
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Servicio de retorno
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Servicio de compras por internet
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Notificaci&oacute;n via web
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Acceso a tu perfil personal
                                            </div>
                                            <!-- PRECIO AEREO -->
                                            <div
                                                v-if="selectedSubsidiary !== ''"
                                                class="text-h5 text-bold q-mt-md"
                                            >
                                                <q-icon
                                                    name="fas fa-plane-departure"
                                                    class="q-mr-sm"
                                                />
                                                $
                                                {{
                                                    ratesAcordingToSelectedSubsidiary
                                                        .find(
                                                            rate =>
                                                                rate.name === 'PLAN BASICO' &&
                                                                rate.type === 'air'
                                                        )
                                                        .rate.toFixed(2)
                                                }}
                                            </div>
                                            <!-- /PRECIO AEREO -->

                                            <!-- PRECIO MARITIMO -->
                                            <div
                                                v-if="selectedSubsidiary !== ''"
                                                class="text-h5 text-bold q-my-md"
                                            >
                                                <q-icon name="fas fa-ship" class="q-mr-sm" />
                                                $
                                                {{
                                                    ratesAcordingToSelectedSubsidiary
                                                        .find(
                                                            rate =>
                                                                rate.name === 'PLAN BASICO' &&
                                                                rate.type === 'sea'
                                                        )
                                                        .rate.toFixed(2)
                                                }}
                                            </div>
                                            <!-- /PRECIO MARITIMO -->

                                            <div
                                                class="text-caption"
                                                v-if="selectedSubsidiary !== ''"
                                            >
                                                * La libra a considerar es la mayor entre el peso
                                                real y el peso volumetrico del paquete.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-xs-12 q-pa-sm">
                                        <div
                                            :class="returnRateClass('PLAN CERO VOLUMEN')"
                                            @click="assingSelectedRate('PLAN CERO VOLUMEN')"
                                        >
                                      
                                            <i class="fas fa-university fa-2x q-mb-sm"></i>
                                            <div class="text-h6 q-mb-sm text-bold">
                                                PLAN PREMIUM
                                            </div>
                                            <q-separator class="q-mb-sm" dark />
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                PO Box personal en Miami
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Carga aérea y carga maritima
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Vuelos diarios Miami - Panam&aacute;
                                            </div>
                                            <div v-if="registrationData.absorbedCompany =='LifeLine'" class="text-subtitle2 text-bold q-mb-sm">
                                                10% de descuento ( 20%  de descuento para compras mayores a 60.00$) en la compra de suplementos
                                            </div>
                                            <div v-if="registrationData.absorbedCompany =='LifeLine'" class="text-subtitle2 text-bold q-mb-sm">
                                                Asesoría en suplementación por cada compra
                                            </div>
                                            <div v-if="registrationData.absorbedCompany =='LifeLine'" class="text-subtitle2 text-bold q-mb-sm">
                                                10% de descuento al adquirir cualquier servicio de Lifeline
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                <a
                                                    class="text-primary"
                                                    href="/terminos-y-condiciones#reclamos-y-seguros"
                                                    target="_blank"
                                                    >Paqueteria asegurada</a
                                                >
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Entrega a domicilio (ciudad y provincia)
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Servicio de retorno
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Servicio de compras por internet
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Notificaci&oacute;n via web
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Acceso a tu perfil personal
                                            </div>
                                            <!-- PRECIO AEREO -->
                                            <div
                                                v-if="selectedSubsidiary !== ''"
                                                class="text-h5 text-bold q-mt-md"
                                            >
                                                <q-icon
                                                    name="fas fa-plane-departure"
                                                    class="q-mr-sm"
                                                />
                                                $
                                                {{
                                                    ratesAcordingToSelectedSubsidiary
                                                        .find(
                                                            rate =>
                                                                rate.name === 'PLAN CERO VOLUMEN' &&
                                                                rate.type === 'air'
                                                        )
                                                        .rate.toFixed(2)
                                                }}
                                            </div>
                                            <!-- /PRECIO AEREO -->

                                            <!-- PRECIO MARITIMO -->
                                            <div
                                                v-if="selectedSubsidiary !== ''"
                                                class="text-h5 text-bold q-mt-md"
                                            >
                                                <q-icon name="fas fa-ship" class="q-mr-sm" />
                                                $
                                                {{
                                                    ratesAcordingToSelectedSubsidiary
                                                        .find(
                                                            rate =>
                                                                rate.name === 'PLAN CERO VOLUMEN' &&
                                                                rate.type === 'sea'
                                                        )
                                                        .rate.toFixed(2)
                                                }}
                                            </div>
                                            <!-- /PRECIO MARITIMO -->
                                            <div
                                                class="text-caption"
                                                v-if="selectedSubsidiary !== ''"
                                            >
                                                <br>
                                                * 
                                                La libra a considerar es unicamente el peso real
                                                del paquete.

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-xs-12 q-pa-sm">
                                        <div
                                            v-if="registrationData.absorbedCompany!='LifeLine'"
                                            :class="returnRateClass('PLAN BUSINESS')"
                                            @click="assingSelectedRate('PLAN BUSINESS')"
                                        >
                                            <i class="fas fa-building fa-2x q-mb-sm"></i>
                                            <div class="text-h6 q-mb-sm text-bold">
                                                PLAN BUSINESS
                                            </div>
                                            <q-separator class="q-mb-sm" dark />
                                            <div class="text-subtitle2 text-bold text-primary">
                                                ESPECIAL PARA NEGOCIOS
                                            </div>
                                            <div class="text-h4" style="padding: 75px 0">
                                                Contáctanos para mas informaci&oacute;n
                                            </div>
                                            <div class="text-h4"  v-if="selectedSubsidiary !== ''" style="padding: 65px 0">
                                                
                                                                                         
                                            </div>
                                            <q-btn
                                                label="Mas info"
                                                v-if="selectedSubsidiary !== ''"
                                                color="primary"
                                                class="text-bold q-px-md"
                                                push
                                                @click="businessRateDialog = true"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        v-if="showRateWarning"
                                        class="row q-px-md text-red text-bold text-h6"
                                    >
                                        **Debes escojer una tarifa
                                    </div>
                                </div>
                            </q-step>



                            <q-step
                                v-else
                                :name="2"
                                title="Tu plan"
                                icon="fas fa-dollar-sign"
                                :done="step > 2"
                            >
                            
                                <div   v-if="registrationData.absorbedCompany!='LifeLine'" class="text-h4 text-center text-primary">
                                    <span class="text-bold text-secondary">2.</span> Escoge tu plan
                                </div>
                                <div   v-else  class="text-h4 text-center text-primary">
                                    <span class="text-bold text-secondary">2.</span> Tu plan
                                </div>
                                <q-separator
                                    color="accent"
                                    style="
                                        width: 50%;
                                        margin: 0 auto;
                                        height: 2px;
                                        margin-bottom: 20px;"
                                />
                                <!--    
                                <div class="row">
                                    <div class="col q-pa-sm">
                                        <q-select
                                            filled
                                            class="y-go-1x"
                                            label="Sucursal de preferencia *"
                                            :options="Object.values(subsidiaries)"
                                            :rules="[
                                                val => val.label.length > 0 || 'El campo es obligatorio',
                                            ]"
                                            v-model="selectedSubsidiary"
                                        />
                                    </div>
                                </div>
                                -->
                                <div class="row q-mb-md">
                                    
                                    <div class="col-lg-4 col-xs-12 q-pa-sm" >
                                        <div
                                            v-if="registrationData.absorbedCompany!='LifeLine'"
                                            :class="returnRateClass('PLAN BASICO')"
                                            @click="assingSelectedRate('PLAN BASICO')"
                                        >
                                            <i class="fas fa-box-open fa-2x q-mb-sm"></i>
                                            <div class="text-h6 q-mb-sm text-bold">
                                                PLAN STANDARD
                                            </div>
                                            <q-separator class="q-mb-sm" dark />
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                PO Box personal en Miami
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Carga aérea y carga maritima
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Vuelos diarios Miami - Panam&aacute;
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                <a
                                                    class="text-primary"
                                                    href="/terminos-y-condiciones#reclamos-y-seguros"
                                                    target="_blank"
                                                    >Paqueteria asegurada</a
                                                >
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Entrega a domicilio (ciudad y provincia)
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Servicio de retorno
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Servicio de compras por internet
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Notificaci&oacute;n via web
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                Acceso a tu perfil personal
                                            </div>
                                            <!-- PRECIO AEREO -->
                                            <div
                                                v-if="selectedSubsidiary !== ''"
                                                class="text-h5 text-bold q-mt-md"
                                            >
                                                <q-icon
                                                    name="fas fa-plane-departure"
                                                    class="q-mr-sm"
                                                />
                                                $
                                                {{
                                                    ratesAcordingToSelectedSubsidiary
                                                        .find(
                                                            rate =>
                                                                rate.name === 'PLAN BASICO' &&
                                                                rate.type === 'air'
                                                        )
                                                        .rate.toFixed(2)
                                                }}
                                            </div>
                                            <!-- /PRECIO AEREO -->

                                            <!-- PRECIO MARITIMO -->
                                            <div
                                                v-if="selectedSubsidiary !== ''"
                                                class="text-h5 text-bold q-my-md"
                                            >
                                                <q-icon name="fas fa-ship" class="q-mr-sm" />
                                                $
                                                {{
                                                    ratesAcordingToSelectedSubsidiary
                                                        .find(
                                                            rate =>
                                                                rate.name === 'PLAN BASICO' &&
                                                                rate.type === 'sea'
                                                        )
                                                        .rate.toFixed(2)
                                                }}
                                            </div>
                                            <!-- /PRECIO MARITIMO -->

                                            <div
                                                class="text-caption"
                                                v-if="selectedSubsidiary !== ''"
                                            >
                                                * La libra a considerar es la mayor entre el peso
                                                real y el peso volumetrico del paquete.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-xs-12 q-pa-sm">
                                        <div
                                            :class="returnRateClass('PLAN CERO VOLUMEN')"
                                            @click="assingSelectedRate('PLAN CERO VOLUMEN')"
                                        >
                                      
                                            <i class="fas fa-university fa-2x q-mb-sm"></i>
                                            
                                            <div  v-if="registrationData.absorbedCompany !='LifeLine'"   class="text-h6 q-mb-sm text-bold">
                                                PLAN PREMIUM
                                            </div>
                                            <div  v-else   class="text-h6 q-mb-sm text-bold">
                                                PLAN PREMIUM LIFELINE
                                            </div>
                                            <q-separator class="q-mb-sm" dark />


                                            <div
                                                v-if="selectedSubsidiary !== ''"
                                                class="text-subtitle2 text-bold q-mb-sm"
                                            >
                                            <table style = "width:100%">
                                                <tr>
                                                <td><q-icon name="fas fa-plane-departure" style="font-size: 3rem;" class="big-icon"  :size="xl"/></td>
                                                <td></td>
                                                <td><q-icon name="fas fa-ship" style="font-size: 3rem;" class="big-icon"  :size="xl" /></td>
                                                </tr>

                                                <tr>
                                                <td><small>tarifa establecida por libra (lbs)</small><br><br>
                                                <p style="font-size:30px;">  
                                                $
                                                {{
                                                    ratesAcordingToSelectedSubsidiary
                                                        .find(
                                                            rate =>
                                                                rate.name === 'PLAN CERO VOLUMEN' &&
                                                                rate.type === 'air'
                                                        )
                                                        .rate.toFixed(2)
                                                }}*</p> 
                                                <br>Vuelos diarios <br> MIA - PTY</td>
                                                <td></td>
                                                <td><small>tarifa establecida por pie cúbico (ft3)</small><br><br>
                                                <p style="font-size:30px;">  
                                                $
                                                {{
                                                    ratesAcordingToSelectedSubsidiary
                                                        .find(
                                                            rate =>
                                                                rate.name === 'PLAN CERO VOLUMEN' &&
                                                                rate.type === 'sea'
                                                        )
                                                        .rate.toFixed(2)
                                                }}*</p>
                                                 <br>Salidas semanales  <br> MIA - PTY</td>
                                                 </tr>
                                            </table>
                                            <br>
                                          

                                            <div  v-if="registrationData.absorbedCompany !='LifeLine'" class="text-subtitle2 text-bold q-mb-sm">
                                                PO Box personal en Miami
                                            </div>
                                           
                                            <div v-if="registrationData.absorbedCompany =='LifeLine'" class="text-subtitle2 text-bold q-mb-sm">
                                               <br> 10% de descuento en la compra de suplementos y servicios Lifeline (20% en suplementos para compras mayores a $60.00)
                                            </div>
                                            <div v-if="registrationData.absorbedCompany =='LifeLine'" class="text-subtitle2 text-bold q-mb-sm">
                                                Asesoría en suplementación por cada compra
                                            </div>
                                            <div v-if="registrationData.absorbedCompany =='LifeLine'" class="text-subtitle2 text-bold q-mb-sm">
                                                10% de descuento al adquirir cualquier servicio de Lifeline
                                            </div>
                                            <div v-if="registrationData.absorbedCompany =='LifeLine'" class="text-subtitle2 text-bold q-mb-sm">
                                                Plan de Lealtad gratis por 4 meses
                                            </div>
                                            <div  class="text-subtitle2 text-bold q-mb-sm" >
                                                        Notificaci&oacute;n via web
                                            </div>
                                            <div class="text-subtitle2 text-bold q-mb-sm">
                                                <a
                                                    class="text-primary"
                                                    href="/terminos-y-condiciones#reclamos-y-seguros"
                                                    target="_blank"
                                                    >Paqueteria asegurada</a
                                                >
                                            </div>
                                            <q-separator class="q-mb-sm" dark />
                                            <div v-if="registrationData.absorbedCompany =='LifeLine'" >
                                                    <div class="text-caption">
                                                        Entrega a domicilio (ciudad y provincia)**
                                                    </div>
                                                    <div class="text-caption">
                                                        Servicio de retorno**
                                                    </div>
                                                    <div class="text-caption">
                                                        Servicio de compras por internet**
                                                    </div>
                                                  
                                            </div>
                                            <div v-else >
                                                    <div class="text-subtitle2 text-bold q-mb-sm">
                                                        Entrega a domicilio (ciudad y provincia)
                                                    </div>
                                                    <div class="text-subtitle2 text-bold q-mb-sm">
                                                        Servicio de retorno
                                                    </div>
                                                    <div class="text-subtitle2 text-bold q-mb-sm">
                                                        Servicio de compras por internet
                                                    </div>
                                        
                                            </div>
                                            <div  v-if="registrationData.absorbedCompany !='LifeLine'" class="text-subtitle2 text-bold q-mb-sm">
                                                Acceso a tu perfil personal
                                            </div>
                                            <!-- PRECIO AEREO -->
                                      
                                            <!-- /PRECIO AEREO -->

                                            <!-- PRECIO MARITIMO -->
                                           
                                            <!-- /PRECIO MARITIMO -->
                                            <div
                                                class="text-caption"
                                                v-if="selectedSubsidiary !== ''"
                                            >
                                                <br>
                                                * 
                                                La libra a considerar es unicamente el peso real
                                                del paquete. <br>
                                                ** Conlleva cargos adicionales. Gratis con plan de lealtad

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-xs-12 q-pa-sm">
                                        <div
                                            v-if="registrationData.absorbedCompany!='LifeLine'"
                                            :class="returnRateClass('PLAN BUSINESS')"
                                            @click="assingSelectedRate('PLAN BUSINESS')"
                                        >
                                            <i class="fas fa-building fa-2x q-mb-sm"></i>
                                            <div class="text-h6 q-mb-sm text-bold">
                                                PLAN BUSINESS
                                            </div>
                                            <q-separator class="q-mb-sm" dark />
                                            <div class="text-subtitle2 text-bold text-primary">
                                                ESPECIAL PARA NEGOCIOS
                                            </div>
                                            <div class="text-h4" style="padding: 75px 0">
                                                Contáctanos para mas informaci&oacute;n
                                            </div>
                                            <div class="text-h4"  v-if="selectedSubsidiary !== ''" style="padding: 65px 0">
                                                
                                                                                         
                                            </div>
                                            <q-btn
                                                label="Mas info"
                                                v-if="selectedSubsidiary !== ''"
                                                color="primary"
                                                class="text-bold q-px-md"
                                                push
                                                @click="businessRateDialog = true"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        v-if="showRateWarning"
                                        class="row q-px-md text-red text-bold text-h6"
                                    >
                                        **Debes escojer una tarifa
                                    </div>
                                </div>
                                </div>
                            </q-step>



                            <q-step
                                :name="3"
                                title="Dirección de entrega"
                                icon="fas fa-map-marker-alt"
                                :done="step > 3"
                            >
                                <div class="text-h4 text-center text-primary">
                                    <span class="text-bold text-secondary">3.</span> Dirección de
                                    entrega
                                </div>
                                <q-separator
                                    color="accent"
                                    style="
                                        width: 50%;
                                        margin: 0 auto;
                                        height: 2px;
                                        margin-bottom: 20px;
                                    "
                                />
                                <div class="row">
                                    <div class="col q-pa-sm">
                                        <GoogleMaps
                                            @markerPosition="setMarkerPosition"
                                            @newMarkerPosition="setNewMarkerPosition"
                                            :editable="true"
                                            :markers="markers"
                                            :mapCenter="center"
                                        ></GoogleMaps>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col q-pa-sm">
                                        <q-select
                                            filled
                                            class="y-go-1x"
                                            label="Distrito"
                                            :options="locations"
                                            map-options
                                            behavior="dialog" 
                                            emit-value
                                            :rules="[val => !!val || 'Campo obligatorio']"
                                            v-model="registrationData.district"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col q-pa-sm">
                                        <q-input
                                            filled
                                            class="y-go-1x"
                                            label="Direccion de entrega"
                                            :rules="[val => !!val || 'Campo obligatorio']"
                                            v-model="registrationData.address"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col q-pa-sm">
                                        <q-input
                                            filled
                                            class="y-go-1x"
                                            label="Notas adicionales de dirección"
                                            v-model="registrationData.addressExtra"
                                        />
                                    </div>
                                </div>

                                <div
                                    v-if="showMapPinWarning"
                                    class="row q-px-md text-red text-bold text-h6"
                                >
                                    **Por favor asegurate de mover el pin a tu dirección correcta
                                </div>
                            </q-step>

                            <q-step :name="4" title="Completado" icon="fas fa-check-double">
                                <div v-if="!successResponse">
                                    <div class="full-width text-center q-mb-md">
                                        <q-spinner-puff color="primary" size="5em" />
                                    </div>

                                    <div class="text-h5 text-center text-bold text-accent q-mb-md">
                                        Estamos guardando tu información<br />En unos momentos tu
                                        casillero estara listo.
                                    </div>
                                    <q-linear-progress
                                        dark
                                        stripe
                                        rounded
                                        size="20px"
                                        :value="progress"
                                        color="primary"
                                    />
                                </div>
                                <div v-else>
                                    <div class="full-width text-center q-mb-md">
                                        <i class="fas fa-check fa-4x text-secondary" />
                                    </div>
                                    <div class="text-h5 text-center text-bold text-accent q-mb-md">
                                        ¡GUARDADO CON EXITO!<br />
                                        Ya puedes acceder a tu casillero.
                                    </div>

                                    <div class="full-width text-center">
                                        <!-- ESTE BOTON DEBE CERRAR EL DIALOG -->
                                        <q-btn
                                            push
                                            label="Ver mi casillero"
                                            class="text-bold"
                                            color="accent"
                                            @click="$emit('close-registration-dialog')"
                                        />
                                    </div>
                                </div>
                            </q-step>

                            <template v-slot:navigation v-if="step <= 3">
                                <q-stepper-navigation>
                                    <q-btn
                                        color="accent"
                                        push
                                        class="text-bold"
                                        :label="step === 3 ? 'Finalizar' : 'Siguiente'"
                                        type="submit"
                                    />
                                    <q-btn
                                        v-if="step > 1"
                                        flat
                                        color="primary"
                                        @click="$refs.stepper.previous()"
                                        label="Atras"
                                        class="q-ml-sm"
                                    />
                                </q-stepper-navigation>
                            </template>
                        </q-stepper>
                    </q-form>
                </div>
                <div class="col desktop-only"></div>
            </div>
        </div>


        <q-dialog v-model="alertDialog" persistent>
            <q-card>
                <q-card-section class="text-center">
                    <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                </q-card-section> 
                <q-card-section>
                    <div class="text-h6 text-center">
                       Debes completar los siguientes PASOS para poder usar tu casillero!
                    </div>
                </q-card-section>
                <q-card-actions>
                    <q-space />
                    <!-- <q-btn
                        flat
                        color="red-7"
                        label="cancelar"
                        @click="businessRateDialog = false"
                    /> -->
                    <q-btn
                        flat
                        color="primary"
                        label="Aceptar"
                        @click="alertDialog = false"
                    />
                </q-card-actions>
            </q-card>
        </q-dialog>


        <q-dialog v-model="businessRateDialog" persistent>
            <q-card>
                <!-- <q-card-section class="text-center">
                    <i class="fas fa-building fa-3x q-mb-sm" />
                </q-card-section> -->
                <q-card-section>
                    <div class="text-h6 text-center">
                        Al seleccionar este plan se enviara una solicitud para ser revisada por uno
                        de nuestros administradores.
                    </div>
                </q-card-section>
                <q-card-actions>
                    <q-space />
                    <!-- <q-btn
                        flat
                        color="red-7"
                        label="cancelar"
                        @click="businessRateDialog = false"
                    /> -->
                    <q-btn
                        flat
                        color="primary"
                        label="Aceptar"
                        @click="businessRateDialog = false"
                    />
                </q-card-actions>
            </q-card>
        </q-dialog>
      
    </q-card>
</template>

<script>
import GoogleMaps from '../../components/general/GoogleMaps'
import * as api from '@/api/api'
import SubsidiariesNames from '@/enums/subsidiariesNames'
export default {
    props: {
        userData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            businessRateDialog: false,
            alertDialog: true,
            registrationData: {
                name: '',
                lastName: '',
                countryCode: '+507',
                phone: '',
                district: '',
                address: '',
                addressExtra: '',
                subsidiary: '',
                birthdate: '',
                absorbedCompany: '',
                company: '',
            },
            selectedSubsidiary: '',
            selectedRate: '',
            ratesAcordingToSelectedSubsidiary: [],
            location: [],
            markers: [],
            center: {},
            countryCodes: require('@/assets/country_codes.json'),
            step: 1,
            progress: 0,
            allRates: [],
            showRateWarning: false,
            showMapPinWarning: false,
            successResponse: false,
            locationVerified: false,
            locations: [],
            subsidiaries: [],
            selectedRate: '',
            districts: [],
            displayAlert: false,
            alertTitle: '',
            alertMessage: '',
            alertType: '',
        }
    },
    watch: {
        userData: {
            immediate: true,
            handler(newValue, oldValue) {
                this.registrationData.name = newValue.name
                this.registrationData.lastName = newValue.lastName
                this.registrationData.absorbedCompany = newValue.absorbedCompany
            },
        },
        selectedSubsidiary(newValue, oldValue) {
            let currentSubsidiary = Object.values(this.subsidiaries).indexOf(newValue)
            this.ratesAcordingToSelectedSubsidiary = this.allRates.filter(
                rate => rate.subsidiary === currentSubsidiary
            )
            this.registrationData.subsidiary = currentSubsidiary
            return
        },
    },
    computed: {
        uid() {
            return this.$store.getters.uid
        },
    },
    methods: {
        async assingSelectedRate(rateName) {
            if (this.selectedSubsidiary) {
                this.registrationData.airRate =
                    (await this.ratesAcordingToSelectedSubsidiary.find(
                        rate => rate.name === rateName && rate.type === 'air'
                    ).id) || ''
                this.registrationData.seaRate =
                    (await this.ratesAcordingToSelectedSubsidiary.find(
                        rate => rate.name === rateName && rate.type === 'sea'
                    ).id) || ''
                this.selectedRate = this.registrationData.airRate
                this.getDistrict(this.selectedSubsidiary.id);
            }
        },
        returnRateClass(rateName) {
            if (this.selectedSubsidiary) {
                let selectedRate = this.ratesAcordingToSelectedSubsidiary.find(
                    rate => rate.id === this.selectedRate
                )
                if (selectedRate !== undefined && selectedRate.name === rateName) {
                    return 'rateTileSelected q-pa-md rounded-borders text-center'
                }
            }
            return 'rateTile q-pa-md rounded-borders text-center'
        },
        getDistrict(subsidiaryId){
            this.locations.length = 0;
            const districtList = this.districts.filter(district => district.province === subsidiaryId);
            districtList.forEach((district) => {
                this.locations.push({
                    label: district.name,
                    value: district.name,
                })    
            })
        },
        splitNParts: function* (num, parts) {
            let sumParts = 0
            for (let i = 0; i < parts - 1; i++) {
                const pn = Math.ceil(Math.random() * (num - sumParts))
                yield pn
                sumParts += pn
            }
            yield num - sumParts
        },
        checkSteppers() {
            if (this.step == 3) {
                let customProgress = [...this.splitNParts(90, 10)]
                for (let i = 0; i < 10; i++) {
                    let time = Math.random() * 3500
                    setTimeout(() => {
                        this.progress = this.progress + customProgress[i] / 100
                    }, time)
                }
                setTimeout(() => {
                    this.registrationData.isUpdated = true
                    this.registrationData.coordinates = this.location
                    api.UpdateUserInformationById({
                        uid: this.uid,
                        user: this.registrationData,
                    })
                        .then(async response => {
                            setTimeout(() => {
                                this.progress = 1
                                this.successResponse = true
                            }, 500)
                            let userProfile = await api
                                .returnUserProfileInformation({uid: this.uid})
                                .then(resp => resp.data.data)
                            await this.$store.commit('SET_USER', userProfile.user)
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }, 3000)
            }
            switch (this.$refs.stepper.panelIndex) {
                case 0:
                    console.log('I`m alive')
                    this.alertTitle = '¡Bienvenido!'
                    this.alertMessage = 'Debes terminar tu registro, de lo contrario no tendras tu casillero'
                    this.alertType = 'success'
                    this.displayAlert = true
                    if( this.registrationData.absorbedCompany == 'LifeLine'){
                         this.assingSelectedRate('PLAN CERO VOLUMEN');
                    } 
                    this.$refs.stepper.next()
                    break
                case 1:
                    
                    if (this.registrationData.rate === '' || this.selectedRate ==='') {
                        this.showRateWarning = true
                        return
                    }
                    this.showRateWarning = false
                    this.$refs.stepper.next()
                    break
                case 2:
                    this.$refs.stepper.next()
                    break
            }
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.center = {
                        lat: parseFloat(position.coords.latitude),
                        lng: parseFloat(position.coords.longitude),
                    }
                    this.markers.push({position: this.center})
                },
                error => {
                    this.center = {
                        lat: parseFloat(9.068463),
                        lng: parseFloat(-79.452694),
                    }
                    this.markers.push({position: this.center})
                }
            )
        },
        setMarkerPosition(event) {
            this.locationVerified = false
            this.location = event
        },
        setNewMarkerPosition(event) {
            this.locationVerified = false
            this.markers = [{position: event}]
            this.location = event
        },
        // validateLocation() {
        // //DEPRECATED: The client requested this feature to be off.
        //     if (this.location.length <= 0) {
        //         this.showMapPinWarning = true
        //         return
        //     }
        //     fetch(
        //         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.location.lat},${this.location.lng}&key=AIzaSyCDzDbwg-PqYOIAMgNE7A70gauYHeOel5A`
        //     )
        //         .then(response => response.json())
        //         .then(data => {
        //             if (data.status !== 'REQUEST_DENIED') {
        //                 this.registrationData.address = data.results[0].formatted_address
        //                 this.locationVerified = true
        //             }
        //         })
        //         .catch(error => console.log(error))
        // },
    },
    components: {
        GoogleMaps,
    },
    async mounted() {
        this.geolocate()
        await api.ReturnAllOnBoardingRates().then(response => {
            this.allRates = response.data.data
        });

        await api.GetAllDistricts().then(response => {
            this.districts = response.data.data;
        });
        
        SubsidiariesNames.forEach(subsidiary => {
            subsidiary.label = 'Sucursal de ' + subsidiary.name;
            this.subsidiaries.push(subsidiary);
        });
        //this.businessRateDialog = true
        /*
                this.alertTitle = '¡Bienvenido! XD'
                this.alertMessage = 'Debes terminar tu registro, de lo contrario no tendras tu casillero'
                this.alertType = 'success'
                this.displayAlert = true
         */       
    },
}
</script>
